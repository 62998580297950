<template>
  <div>
    <div
      class="flex-grow flex flex-col m-2 p-6 bg-white border-t border-b sm:rounded sm:border shadow overflow-hidden"
    >
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <h3 class="font-semibold text-base text-gray-800">
            {{ titleorders }}
          </h3>
        </div>
      </div>
      <div class="flex flex-wrap items-center mt-5">
        <div
          v-if="userTypeorders == 'Admin' || userTypeorders == 'CEO'"
          class="relative w-full px-4 max-w-full flex-grow flex-1"
        >
          <div
            class="border h-10 hover:border-green-500  focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1"
          >
            <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
              <p>
                <label for="category" class="bg-white text-gray-700 px-1"
                  >{{$t('seller')}} : <span :class="$colors.required">*</span></label
                >
              </p>
            </div>
            <p>
              <v-select
                v-model="timeserchseller"
                :options="sellers"
                label="fullName"
                @input="ChangeSellerOrders"
              ></v-select>
            </p>
          </div>
        </div>
        <div
          class="relative w-full px-4 max-w-full flex-grow flex-1 text-right"
        >
          <div
            class="border hover:border-green-500  w-full focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1"
          >
            <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
              <p>
                <label for="status" class="bg-white text-gray-700 px-1"
                  >{{$t('date')}} : <span :class="$colors.required">*</span></label
                >
              </p>
            </div>
            <p>
              <select
                id="type"
                autocomplete="type"
                @change="ChangeTypeDateOrders($event)"
                v-model="timeserchtype"
                class="py-2 text-xs capitalize px-2 outline-none block h-full w-full bg-white"
              >
                <option class="bg-white text-gray-600" value="today">
                  {{$t('today')}}
                </option>
                <option class="bg-white text-gray-600" value="thismonth">
                  {{$t('this_month')}}
                </option>
                <option class="bg-white text-gray-600" value="thisyear">
                  {{$t('this_year')}}
                </option>
                 <option value="betweendate" class="bg-white text-gray-600"> {{$t('between_two_date')}} </option>
              </select>
            </p>
          </div>
        </div>
      </div>
       <div v-if="filter && timeserchtype=='betweendate'" class="relative w-full px-2 max-w-full flex-grow flex-1 text-right">
          <div  class="inline-block mt-4">
              <div class="inline-block w-2/5 pr-1">
                <div class="border hover:border-blue-400 w-full focus-within:border-blue-500 focus-within:text-blue-500 transition-all duration-500 relative rounded p-1">
                  <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"><p><label for="date" class="bg-white text-gray-700 px-1">From <span class="text-blue-500">*</span></label></p></div>
                    <p>  <input  v-model="fromDate" class="w-full px-1 py-1 text-gray-700 bg-gray-200 rounded" id="from" type="date"> </p>
                </div>
              </div>
                <div class="inline-block  w-2/5 pr-1">
                  <div class="border hover:border-blue-400 w-full focus-within:border-blue-500 focus-within:text-blue-500 transition-all duration-500 relative rounded p-1">
                  <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"><p><label for="date" class="bg-white text-gray-700 px-1">To <span class="text-blue-500">*</span></label></p></div>
                    <p>  <input  v-model="toDate" class="w-full px-1 py-1 text-gray-700 bg-gray-200 rounded" id="to" type="date"> </p>
                </div>
              </div>
              <div class="inline-block  w-1/5 pr-1">
                  <button @click="FindRecordOrders()"  :disabled="fromDate==null || toDate==null" class="w-full px-2 py-2 text-white font-light tracking-wider bg-gray-900 rounded">Find</button>
              </div>
          </div>
        </div>
      <canvas :id="idorders" style="height: 300px"></canvas>
    </div>
  </div>
</template>



<script>
import Chart from "chart.js";
export default {
  props: ["chartData", "options", "Labels", "labelstring"],
  props: [
    "dataorders",
    "sellers",
    "options",
    "labelstringo",
    "timesercho",
    "timeserchsellerorders",
    "idorders",
    "titleorders",
    "typeorders",
    "userTypeorders",
    "Labels",
    "filter",
    "from",
    "to"

  ],
  data() {
    return {
      myChart: "",
      timeserchtype: this.timesercho,
      timeserchseller: this.timeserchsellerorders,
      fromDate:this.from,
      toDate:this.to,
    };
  },
  async mounted() {
    await this.drawChart(this.dataorders, this.Labels, this.labelstringo);
  },
  methods: {
    FindRecordOrders: function () {
      this.$emit("FindRecordOrders",this.fromDate,this.toDate);
    },
    ChangeTypeDateOrders: function (val) {
      this.$emit("ChangeTypeDateOrders", val);
    },
    ChangeSellerOrders: function (val) {
      this.$emit("ChangeSellerOrders", val);
    },
    drawChart(chartData, labels, labelstring) {
      var ctx = document.getElementById(this.idorders);
      this.myChart = new Chart(ctx, {
        type: "bar",

        data: {
          labels: labels,
          datasets: [
            {
              label: 'orders',
              backgroundColor: "#36a2eb",
              borderColor: "#36a2eb",
              data: chartData,
              fill: false,
              barThickness: 20,
              ticks: {
                fontSize: 10,
              },
            },
          ],
        },
        options: {
          showDatapoints: true,
          responsive: true,
          maintainAspectRatio: true,
          title: {
            display: true,
            text: 'Total Orders: '+ chartData.reduce((a, b) => a + b, 0),
            position: 'top',
            fontSize: 16,
            fontColor: '#111',
            padding: 20
        },
          scales: {
            xAxes: [
              {
                ticks: {
                  fontSize: 15,
                },
                display: true,
                scaleLabel: {
                  display: true,
                  labelString: labelstring,
                },
                gridLines: {
                  borderDash: [2],
                  borderDashOffset: [2],
                  color: "rgba(33, 37, 41, 0.3)",
                  zeroLineColor: "rgba(33, 37, 41, 0.3)",
                  zeroLineBorderDash: [2],
                  zeroLineBorderDashOffset: [2],
                },
              },
            ],
            yAxes: [
              {
                ticks: {
                  fontSize: 15,
                },
                display: true,
                scaleLabel: {
                  display: false,
                  labelString: "Value",
                },
                gridLines: {
                  borderDash: [2],
                  drawBorder: false,
                  borderDashOffset: [2],
                  color: "rgba(33, 37, 41, 0.2)",
                  zeroLineColor: "rgba(33, 37, 41, 0.15)",
                  zeroLineBorderDash: [2],
                  zeroLineBorderDashOffset: [2],
                },
              },
            ],
          },
        },
      });
    },
  },
  watch: {
    Labels: function () {
      if (this.myChart != null) {
        this.myChart.destroy();
      }
      this.drawChart(this.dataorders, this.Labels, this.labelstringo);
    },
    dataorders: function () {
      if (this.myChart != null) {
        this.myChart.destroy();
      }
      this.drawChart(this.dataorders, this.Labels, this.labelstringo);
    },
    labelStringo: function () {
      if (this.myChart != null) {
        this.myChart.destroy();
      }
      this.drawChart(this.dataorders, this.Labels, this.labelstringo);
    },
  },
};
</script>
<style scoped>
body {
  font-family: "IBM Plex Mono", sans-serif;
}
[x-cloak] {
  display: none;
}

.line {
  background: repeating-linear-gradient(
    to bottom,
    #eee,
    #eee 1px,
    #fff 1px,
    #fff 8%
  );
}
.tick {
  background: repeating-linear-gradient(
    to right,
    #eee,
    #eee 1px,
    #fff 1px,
    #fff 5%
  );
}
</style>