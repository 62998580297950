<template>
  <div class="card radius-10">
     <div class="card-body">
            <div  class="d-flex align-items-center">
                <div><h5  class="mb-0 fz-17 font-bold">{{statSubtitle}}</h5></div>
                <div  class="font-22 ms-auto"><i  class="bx bx-dots-horizontal-rounded"></i></div>
            </div>
            <div v-if="filterseller && currentUser && currentUser.type && currentUser.role && currentUser.role.name" class="relative filter-dash justify-start mt-3">
                  <div class="flex p-1">
                    <div :class="action=='Products' || action==='Revenues' || action==='Processed' || action==='TopProducts'?'ww-40':'ww-3/7'" v-if="currentUser.role.name!='Seller' && currentUser.type!='Seller'">
                        <div class="flex">
                                <div class="w-10 z-1 pl-1 text-center pointer-events-none flex items-center justify-center">
                                   <svg xmlns="http://www.w3.org/2000/svg" class="text-gray-600 fill-current" width="18" height="18" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7.753 18.305c-.261-.586-.789-.991-1.871-1.241-2.293-.529-4.428-.993-3.393-2.945 3.145-5.942.833-9.119-2.489-9.119-3.388 0-5.644 3.299-2.489 9.119 1.066 1.964-1.148 2.427-3.393 2.945-1.084.25-1.608.658-1.867 1.246-1.405-1.723-2.251-3.919-2.251-6.31 0-5.514 4.486-10 10-10s10 4.486 10 10c0 2.389-.845 4.583-2.247 6.305z"/></svg>
                                </div>
                                  <v-select
                                    @search="getUsersDebance($event,'ouaka')"
                                    @input="CheckSellerProducts($event,action,'seller')"
                                    label="fullName"
                                    :placeholder='$t("seller")'
                                    :options="sellers"
                                    v-model="sellerdata"
                                    class="text-gray-600 bg-white w-full -ml-10 pl-8 pr-2 py-1 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500"
                                
                                ></v-select>
                        </div>
                    </div>
                    <div v-if="action && action!='Products' && action!='Revenues' && action!='Processed' && action!='TopProducts'" class="px-1" :class="currentUser.role.name=='Seller' && currentUser.type=='Seller'?'w-11/12':'ww-3/7'">
                        <div class="flex">
                            <div class="w-10 z-1 pl-1 text-center pointer-events-none flex items-center justify-center">
                              <svg class="text-gray-600 fill-current" width="15"  height="15" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24"><path d="M11.5 23l-8.5-4.535v-3.953l5.4 3.122 3.1-3.406v8.772zm1-.001v-8.806l3.162 3.343 5.338-2.958v3.887l-8.5 4.534zm-10.339-10.125l-2.161-1.244 3-3.302-3-2.823 8.718-4.505 3.215 2.385 3.325-2.385 8.742 4.561-2.995 2.771 2.995 3.443-2.242 1.241v-.001l-5.903 3.27-3.348-3.541 7.416-3.962-7.922-4.372-7.923 4.372 7.422 3.937v.024l-3.297 3.622-5.203-3.008-.16-.092-.679-.393v.002z"/></svg>
                            </div>
                              <v-select
                              @search="getProducts($event,'ouaka')"
                              @input="CheckSellerProducts($event,action,'product')"
                              label="name"
                              placeholder="Product name ..."
                              :options="products"
                              v-model="productdata"
                              class="text-gray-600 bg-white w-full -ml-10 pl-8 pr-2 py-1 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500"
                            ></v-select>
                        </div>
                    </div>
                    <div :class="action=='Products' || action==='Revenues' || action==='Processed' || action==='TopProducts'?currentUser.role.name!='Seller' && currentUser.type!='Seller'?'pl-1 ww-40':'ww-90 px-1':'ww-3/7'"  v-if="filter && action!='Expeditions'">
                        <div class="rounded-lg border-2 border-gray-200 hover:border-blue-400 w-full focus-within:border-blue-500 focus-within:text-blue-500 transition-all duration-500 relative rounded p-1">
                            <p>  
                                <select id="type" :name="action" autocomplete="type" @change="ChangeDatevalue($event)" v-model="timeserchtype" class="py-2 text-xs text-gray-600 capitalize px-2 cursor-pointer outline-none block h-full w-full">
                                    <option class="bg-white text-gray-600" value="all">All</option>
                                    <option class="bg-white text-gray-600" value="today">Today</option>
                                    <option class="bg-white text-gray-600" value="yesterday">Yesterday</option>
                                    <option class="bg-white text-gray-600" value="thisweek">This week</option>
                                    <option class="bg-white text-gray-600" value="lastweek">Last week</option>
                                    <option class="bg-white text-gray-600" value="thismonth">This Month</option>
                                    <option class="bg-white text-gray-600" value="lastmonth">Last Month</option> 
                                    <option class="bg-white text-gray-600" value="thisyear">This Year</option>
                                    <option class="bg-white text-gray-600" value="betweendate">Between two date</option>
                                </select>
                              </p>
                          </div>
                    </div>
                    <div class="ww-1/7 flex justify-center items-center">
                        <svg v-if="currentUser.role.name=='Seller' && currentUser.type=='Seller' && (action==='Revenues' || action==='Processed' || action==='Products' || action==='TopProducts')" :class="loading?'btn-disable':''" :disabled="loading"  @click="removedata(action)" xmlns="http://www.w3.org/2000/svg" class="text-gray-600 fill-current cursor-pointer"  width="20" height="20" viewBox="0 0 24 24"><path d="M5 18c4.667 4.667 12 1.833 12-4.042h-3l5-6 5 6h-3c-1.125 7.98-11.594 11.104-16 4.042zm14-11.984c-4.667-4.667-12-1.834-12 4.041h3l-5 6-5-6h3c1.125-7.979 11.594-11.104 16-4.041z"/></svg>
                        <svg v-else :class="loading?'btn-disable':''" :disabled="loading" @click="removedata(action)" xmlns="http://www.w3.org/2000/svg" class="text-gray-600 fill-current cursor-pointer"  width="20" height="20" viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6 16.538l-4.592-4.548 4.546-4.587-1.416-1.403-4.545 4.589-4.588-4.543-1.405 1.405 4.593 4.552-4.547 4.592 1.405 1.405 4.555-4.596 4.591 4.55 1.403-1.416z"/></svg>
                    </div>
                  </div>
                  <div v-if="filter && timeserchtype=='betweendate'" class="p-1">
                  <!-- <div class="inline-block mt-2 ww-45 pr-1">
                      <div class="border hover:border-blue-400 w-full focus-within:border-blue-500 focus-within:text-blue-500 transition-all duration-500 relative rounded p-1">
                          <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"><p><label for="date" class="bg-white text-gray-700 px-1">From <span class="text-blue-500">*</span></label></p></div>
                          <p>  <input :name="action" v-model="fromDate" class="w-full px-1 py-1 text-gray-700 bg-gray-200 rounded" id="from" type="date"> </p>
                      </div>
                  </div>
                  <div class="inline-block mt-2 ww-45 pr-1">
                      <div class="border hover:border-blue-400 w-full focus-within:border-blue-500 focus-within:text-blue-500 transition-all duration-500 relative rounded p-1">
                          <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"><p><label for="date" class="bg-white text-gray-700 px-1">To <span class="text-blue-500">*</span></label></p></div>
                          <p>  <input :name="action" v-model="toDate" class="w-full px-1 py-1 text-gray-700 bg-gray-200 rounded" id="to" type="date"> </p>
                      </div>
                  </div> -->
                  
                  <div class="flex items-center space-x-2 sm:space-x-2">
                        <date-picker placeholder="Range date ..." format="YYYY-MM-DD" type="date" class="w-full-imp  shadow" v-model="fromDate" range></date-picker>
                        <!-- <button @click="FindRecordsBetweenDate()" :disabled="fromDate==null || toDate==null" class="w-full px-2 py-2 text-white font-light tracking-wider bg-gray-900 rounded">Find</button> -->
                        <svg @click="FindRecordsBetweenDate()" :class="!fromDate?'btn-disable':''" :disabled="!fromDate" class="text-gray-600 fill-current cursor-pointer"  width="22" height="22" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M21.172 24l-7.387-7.387c-1.388.874-3.024 1.387-4.785 1.387-4.971 0-9-4.029-9-9s4.029-9 9-9 9 4.029 9 9c0 1.761-.514 3.398-1.387 4.785l7.387 7.387-2.828 2.828zm-12.172-8c3.859 0 7-3.14 7-7s-3.141-7-7-7-7 3.14-7 7 3.141 7 7 7zm-3-8c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm3 0c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm3 0c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1z"/></svg>
                    </div>  
                  </div>
            </div>
            <hr> 
            <div class="relative">
                 <loading :active.sync="loading" :is-full-page="fullPage"></loading>
                 <div>
                     <highcharts :options="chartOptions" ></highcharts>
                 </div>
            </div> 
    </div>       
</div>
</template>

<script>
export default {
  name: "card-v3",
  props: {
    statSubtitle: {default: "Traffic",},
    statSubtitle1: {default: "Traffic",},
    statSubtitle2: {default: "Traffic",},
    statSubtitle3: {default: "Traffic",},
    loading:{required:false},
    timeserch:{},
    from:{},
    to:{},
    filter: {default: false,},
    filterseller: {default: false,},
    id:{type:String},
    color:{type:String},
    cl:{default: "success"},
    action:{type:String},
    currency: {default:false},
    count:{type: Number,default:0},
    datas:{type:Object},
    dataChart:{type:Array},
    currentUser:{type:Object},
    warhouse:{type:Object},
    sellers:{type:Array},
    products:{type:Array},
    seller:{},
    product:{},
    statIconColor: {
      type: String,
      default: "bg-red-500",
    },
  },
  data() {
    return {
      fullPage: false,
      timeserchtype:this.timeserch,
      myChart: "",
      fromDate:this.from,
      toDate:this.to,
      sellerdata:this.seller,
      productdata:this.product,
      chartOptions: { 
                    chart: {
                        type: 'bar',
                        height: 500,
                        renderTo: 'container',
                        styledMode: true
                    },
                    credits: {
                        enabled: false
                    },
                    title: {
                        text: this.statSubtitle1
                    },
                    subtitle: {
                        text: null
                    },
                    xAxis: {
                        type: 'category',
                        // categories: ['Africa', 'America', 'Asia', 'Europe', 'Oceania'],
                        title: {
                            text: this.statSubtitle3,
                        },
                         labels: {
                            overflow: 'justify'
                        }
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: this.statSubtitle2,
                            align: 'high'
                        },
                        labels: {
                            overflow: 'justify'
                        }
                    },
                    tooltip: {
                        valueSuffix: ' quantity'
                    },
                    plotOptions: {
                        series: {
                            stacking: 'normal'
                            },
                        bar: {
                            dataLabels: {
                                enabled: true,
                                color: 'white',
                                align: "right",
                                format: '{y} quantity delivered',
                                inside: false,
                                style: {
                                    fontWeight: 'bold'
                                },
                                verticalAlign: "middle"
                            }
                        }
                    },
                    legend: {
                         enabled: false
                    },
                    series: []
                    
                    // series: [
                    // {
                    //     type: 'bar',
                    //     name: ['ou', 'kl', '635', '203', '2'],
                    //     data: [107, 31, 635, 203, 2]
                    // }]
        }
    };
  },
 watch: {
    dataChart: function () {
      this.drawChart(this.dataChart);

    },
    'statSubtitle1': function () {
        this.chartOptions.title.text=this.statSubtitle1;
        this.chartOptions.yAxis.title.text=this.$t('Quantity order delivered');
        this.chartOptions.xAxis.title.text=this.$t('Products name');
   },
    'seller': function () {
       console.log('seller------',this.seller)
       this.sellerdata=this.seller;
   },
   'product': function () {
      console.log('product------',this.product)
      this.productdata=this.product;
   }
 },
  async mounted(){
     await this.drawChart(this.dataChart);
     if(this.currentUser && this.currentUser.role && this.currentUser.role.name=='Seller' && this.currentUser.type=='Seller') this.sellerdata=this.currentUser;
  },
   methods: {
     getPercentege(countStatus,total){
         return total?Math.round((countStatus * 100) /  total):0;
     },
    FindRecordsBetweenDate: function (val) {
        this.$emit("FindRecordsBetweenDate", this.action,this.fromDate.map( el => this.$moment(el).format('yyyy-MM-DD')),this.toDate);
    },
    getUsersDebance: function (name) {
        this.$emit("getUsersDebance", name);
     }, 
    getProducts: function (name) {
        this.$emit("getProducts", name);
     }, 
    async CheckSellerProducts(seller,action,model){
       this.$emit("CheckSellerProducts", seller,action,model);
    },
    removedata: function (val) {
        this.$emit("removedata", this.action);
     }, 
    drawChart(chartData) {
        this.chartOptions.title.text=this.statSubtitle1;
        this.chartOptions.yAxis.title.text=this.$t('Quantity order delivered');
        this.chartOptions.xAxis.title.text=this.$t('Products');
        this.chartOptions.series=chartData;
         
    },
    ChangeDatevalue: function (val) { 
      // if(val.target.name=="ConfirmationRate")  this.$emit("ChangeDateConfirmationRate", val);
      // if(val.target.name=="Delivery") this.$emit("ChangeDateConfirmationDelevry", val);
      this.$emit("ChangeDatevalue", val);
    },
    Refrechdata: function (val) { 
        this.$emit("Refrechdata", val);
    },
    //  FindRecords: function () {
    //   this.$emit("FindRecords", this.action,this.fromDate,this.toDate);
    // },
   }
};
</script>
<style scoped>
.bg-light-success{
  background-color: #17a00e1c!important;
}
.fs-12{
  font-size: 12px;
}
.bg-light-warning{
  background-color: #d79e0c1c!important;
}
.text-warning{
  color: #e5ab13!important;
}
.bg-light-blue{
  background-color: #106efd21!important;
}
.text-blue{
  color: #106efd!important;
}
.bg-light-ceil{
  background-color: #160fe140!important;
}
.text-ceil{
  color: #160fe1!important;
}
.bg-light-exp{
  background-color: #ff90024d!important;
}
.text-exp{
  color: #ee8b0c!important;
}

.bg-light-marin{
  background-color: #bf1bb921!important;
}
.text-marin{
  color: #bf1bb9!important;
}
.text-warnred{
  color: #ca1556;
}
.ww-3\/7{
   width: 31%;
}
.ww-1\/7{
   width: 7%;
}
.ww-40{
    width: 48%;
}
.ww-45{
    width: 47%;
}
.ww-90{
    width: 95%;
}
.ww-10{
    width: 10%;
}
.ww-3{
    width: 6%;
}
.filter-dash{
    background-color: #f8f9fa;
    transition: all .2s;
    border: 1px solid #dee2e6!important;
}
.z-1 {
    z-index: 1;
}
.card-body{
  flex: 1 1 auto;
  padding: 1rem;
  padding-bottom: 0;
}
.transform-1{
  transform: scale(0.6);
}
.radius-10 {
    border-radius: 10px;
}
hr:not([size]) {
    height: 1px;
}
hr {
    margin: 1rem 0;
    color: inherit;
    background-color: currentColor;
    border: 0;
    opacity: .25;
}
.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0 solid rgba(0,0,0,.125);
    margin-bottom: 1.5rem;
    box-shadow: 0 2px 6px #dadafda6, 0 2px 6px #ceceee8a;
    min-height: 268px;
}
.items-center {
    align-items: center!important;
}
.d-flex {
    display: flex!important;
}
.text-secondary {
    color: #6c757d!important;
}
.mb-0 {
    margin-bottom: 0!important;
}
.my-1 {
    margin-top: 0.25rem!important;
    margin-bottom: 0.25rem!important;
}
.font-13 {
    font-size: 13px;
}
.text-success {
    color: #15ca20!important;
}
.align-middle {
    vertical-align: middle!important;
}
svg {
    display: inline-block;
}
.bg-light-success {
    background-color: #17a00e1c!important;
}
.widgets-icons {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ededed;
    font-size: 26px;
    border-radius: 10px;
}
.ms-auto {
    margin-left: auto!important;
}
.min-w-seller {
  min-height: 256px;
}

</style>