<template>
  <div>
    <div
      class="flex-grow flex flex-col m-2 p-4 bg-white border-t border-b sm:rounded sm:border shadow overflow-hidden"
    >
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <h3 class="font-semibold text-base text-gray-800">
            {{ titledelivery }}
          </h3>
        </div>
      </div>
      <div class="flex flex-wrap items-center mt-5">
        <div v-if="userTypedelivery != 'Seller'" class="relative w-full px-4 max-w-full flex-grow flex-1">
          <div
            class="border h-10 hover:border-green-500  focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1"
          >
            <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
              <p>
                <label for="category" class="bg-white text-gray-700 px-1"
                  >{{$t('seller')}} : <span :class="$colors.required">*</span></label
                >
              </p>
            </div>
            <p>
              <v-select
                v-model="timeserchseller"
                :options="sellers"
                label="fullName"
                @input="ChangeSellerDelivery"
              ></v-select>
            </p>
          </div>
        </div>
        <div class="relative w-1/2 pl-4 mt-2  flex float-right">
          <div
            class="border hover:border-green-500  w-full focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1"
          >
            <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
              <p>
                <label for="status" class="bg-white text-gray-700 px-1"
                  >{{$t('shipping_date')}} : <span :class="$colors.required">*</span></label
                >
              </p>
            </div>
            <p>
              <select
                id="type"
                autocomplete="type"
                @change="ChangeTypeDateDelivery($event)"
                v-model="timeserchtype"
                class="py-2 text-xs capitalize px-2 outline-none block h-full w-full bg-white"
              >
                >
                <option class="bg-white text-gray-600" value="All">
                  {{$t('all')}}
                </option>
                <option class="bg-white text-gray-600" value="today">
                  {{$t('today')}}
                </option>
                <option class="bg-white text-gray-600" value="thisweek">
                  {{$t('this_week')}}
                </option>
                <option class="bg-white text-gray-600" value="lastweek">
                  {{$t('last_week')}}
                </option>
                <option class="bg-white text-gray-600" value="thismonth">
                  {{$t('this_month')}}
                </option>
                <option class="bg-white text-gray-600" value="lastmonth">
                  {{$t('last_month')}}
                </option>
                <option class="bg-white text-gray-600" value="thisyear">
                  {{$t('this_year')}}
                </option>
                <option value="betweendate" class="bg-white text-gray-600"> {{$t('between_two_date')}} </option>
              </select>
            </p>
          </div>
        </div>
        <div v-if="filter && timeserchtype=='betweendate'" class="relative w-full px-4 max-w-full flex-grow flex-1 text-right">
          <div  class="inline-block mt-4">
              <div class="inline-block mt-2 w-2/5 pr-1">
                <div class="border hover:border-blue-400 w-full focus-within:border-blue-500 focus-within:text-blue-500 transition-all duration-500 relative rounded p-1">
                  <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"><p><label for="date" class="bg-white text-gray-700 px-1">From <span class="text-blue-500">*</span></label></p></div>
                    <p>  <input  v-model="fromDate" class="w-full px-1 py-1 text-gray-700 bg-gray-200 rounded" id="from" type="date"> </p>
                </div>
              </div>
                <div class="inline-block mt-2 w-2/5 pr-1">
                  <div class="border hover:border-blue-400 w-full focus-within:border-blue-500 focus-within:text-blue-500 transition-all duration-500 relative rounded p-1">
                  <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"><p><label for="date" class="bg-white text-gray-700 px-1">To <span class="text-blue-500">*</span></label></p></div>
                    <p>  <input  v-model="toDate" class="w-full px-1 py-1 text-gray-700 bg-gray-200 rounded" id="to" type="date"> </p>
                </div>
              </div>
              <div class="inline-block mt-2 w-1/5 pr-1">
                  <button @click="FindRecordShippings()"  :disabled="fromDate==null || toDate==null" class="w-full px-2 py-2 text-white font-light tracking-wider bg-gray-900 rounded">Find</button>
              </div>
          </div>
        </div>
      </div>
      <div class="flex relative flex-wrap justify-center">
        <loading :active.sync="loading" :is-full-page="fullPage"></loading>
           <div class="w-full sm:w-1/2  px-5 py-5">
                    <div  v-for="(item, index) in dataDelivery" :key="index" class="container"  id="alertbox">
                        <div :style="'background-color:'+item.color"  class="container mb-3 flex items-center text-white text-sm font-bold px-4 py-2 relative" role="alert">
                          <svg class="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                          <path d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z"></path>
                          </svg>
                          <p class="capitalize">{{item.status}} : {{item.count}}</p>

                          <span class="absolute top-0 bottom-0 right-0 px-4 py-2 closealertbutton">
                            <i class="material-icons">chevron_right</i>
                          </span>
                        </div>
                     </div>
                    <h1 class="text-center font-semibold text-black-600">Total Shippings : {{count}}</h1>
           </div>
          <div class="w-full sm:w-1/2 sm:pl-2 pt-5">
               <canvas :id="iddelivery" style="height: 250px"></canvas>
           </div>
       </div>
    </div>
  </div>
</template>



<script>
import Chart from "chart.js";
//import ChartDataLabels from "chartjs-plugin-datalabels";
export default {
  props: [
    "dataDelivery",
    "sellers",
    "loading",
    "options",
    "labelstringd",
    "timeserchd",
    "timeserchsellerdelivery",
    "iddelivery",
    "titledelivery",
    "typedelivery",
    "userTypedelivery",
    "filter",
    "count",
    "from",
    "to"
  ],
  data() {
    return {
      myChart: "",
      timeserchtype: this.timeserchd,
      timeserchseller: this.timeserchsellerdelivery,
      fromDate:this.from,
      toDate:this.to,
      fullPage:false
    };
  },
  async mounted() {
    await this.drawChart(this.dataDelivery, this.labelstringd);
  },
  methods: {
     FindRecordShippings: function () {
      this.$emit("FindRecordShippings",this.fromDate,this.toDate);
    },
    ChangeTypeDateDelivery: function (val) {
      this.$emit("ChangeTypeDateDelivery", val);
    },
    ChangeSellerDelivery: function (val) {
      this.$emit("ChangeSellerDelivery", val);
    },
   
    drawChart(chartData, labels, labelstring) {
      var ctx = document.getElementById(this.iddelivery);
      this.myChart = new Chart(ctx, {
        type: "pie",
        showTooltips: true,
        data: {
          labels: chartData.map( el => el.status ) ,
          datasets: [
            {
              label: "Total Orders",
              data: chartData.map( el => el.count ),
              backgroundColor: chartData.map( el => el.color ),
              borderColor: chartData.map( el => el.color ),
              borderWidth: 1,
              weight: 2,
            },
          ],
        },
        options: {
          responsive: true,
          tooltips: {
            callbacks: {
                label: function(tooltipItem, data) {
                  var allData = data.datasets[tooltipItem.datasetIndex].data;
                  var tooltipLabel = data.labels[tooltipItem.index];
                  var tooltipData = allData[tooltipItem.index];
                  var total = 0;
                  for (var i in allData) {
                    total += allData[i];
                  }
                  var tooltipPercentage = Math.round((tooltipData / total) * 100);
                  return tooltipLabel + ': ' + tooltipData + ' (' + tooltipPercentage + '%)';
                }
              }
          },
        legend: {
            display: true,
            position: 'right',
            labels: {
                boxWidth: 20,
                fontColor: '#111',
                padding: 15
            }
        },
          // scales: {
          //   yAxes: [
          //     {
          //       ticks: {
          //         beginAtZero: true,
          //       },
          //     },
          //   ],
          // },
        },
        
      });
    },
  },
  watch: {
    Labels: function () {
      if (this.myChart != null) {
        this.myChart.destroy();
      }
      this.drawChart(this.dataDelivery, this.labelstringd);
    },
    dataDelivery: function () {
      if (this.myChart != null) {
        this.myChart.destroy();
      }
      this.drawChart(this.dataDelivery, this.labelstringd);
    },
    labelstringd: function () {
      if (this.myChart != null) {
        this.myChart.destroy();
      }
      this.drawChart(this.dataDelivery, this.Labels, this.labelstringd);
    },
  },
};
</script>
<style scoped>
body {
  font-family: "IBM Plex Mono", sans-serif;
}
[x-cloak] {
  display: none;
}
.vs__dropdown-toggle {
  border: none;
}
.bg-refused{
  background-color: #9329a8;
}
.line {
  background: repeating-linear-gradient(
    to bottom,
    #eee,
    #eee 1px,
    #fff 1px,
    #fff 8%
  );
}
.tick {
  background: repeating-linear-gradient(
    to right,
    #eee,
    #eee 1px,
    #fff 1px,
    #fff 5%
  );
}
</style>