<template>
 <div class="card radius-10" :style="gardien">
     <div class="card-body">
            <div  class="d-flex align-items-center">
                <div><h5  class="mb-0 fz-17 font-bold">{{statSubtitle}}</h5></div>
                <div  class="font-22 ms-auto"><i  class="bx bx-dots-horizontal-rounded"></i></div>
            </div>
            <div v-if="filterseller && currentUser && currentUser.type && currentUser.role && currentUser.role.name" class="relative filter-dash justify-start mt-3">
                  <div class="flex p-1">
                    <div :class="action=='Products' || action==='Revenues' || action==='Processed'?'ww-40':'ww-3/7'" v-if="currentUser.role.name!='Seller' && currentUser.type!='Seller'">
                        <div class="flex">
                                <div class="w-10 z-1 pl-1 text-center pointer-events-none flex items-center justify-center">
                                   <svg xmlns="http://www.w3.org/2000/svg" class="text-gray-600 fill-current" width="18" height="18" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7.753 18.305c-.261-.586-.789-.991-1.871-1.241-2.293-.529-4.428-.993-3.393-2.945 3.145-5.942.833-9.119-2.489-9.119-3.388 0-5.644 3.299-2.489 9.119 1.066 1.964-1.148 2.427-3.393 2.945-1.084.25-1.608.658-1.867 1.246-1.405-1.723-2.251-3.919-2.251-6.31 0-5.514 4.486-10 10-10s10 4.486 10 10c0 2.389-.845 4.583-2.247 6.305z"/></svg>
                                </div>
                                  <v-select
                                    @search="getUsersDebance($event,'ouaka')"
                                    @input="CheckSellerProducts($event,action,'seller')"
                                    label="fullName"
                                    :placeholder='$t("seller")'
                                    :options="sellers"
                                    v-model="sellerdata"
                                    class="text-gray-600 bg-white w-full -ml-10 pl-8 pr-2 py-1 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500"
                                
                                ></v-select>
                        </div>
                    </div>
                    <div v-if="action && action!='Products' && action!='Revenues' && action!='Processed'" class="px-1" :class="currentUser.role.name=='Seller' && currentUser.type=='Seller'?'w-11/12':'ww-3/7'">
                        <div class="flex">
                            <div class="w-10 z-1 pl-1 text-center pointer-events-none flex items-center justify-center">
                              <svg class="text-gray-600 fill-current" width="15"  height="15" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24"><path d="M11.5 23l-8.5-4.535v-3.953l5.4 3.122 3.1-3.406v8.772zm1-.001v-8.806l3.162 3.343 5.338-2.958v3.887l-8.5 4.534zm-10.339-10.125l-2.161-1.244 3-3.302-3-2.823 8.718-4.505 3.215 2.385 3.325-2.385 8.742 4.561-2.995 2.771 2.995 3.443-2.242 1.241v-.001l-5.903 3.27-3.348-3.541 7.416-3.962-7.922-4.372-7.923 4.372 7.422 3.937v.024l-3.297 3.622-5.203-3.008-.16-.092-.679-.393v.002z"/></svg>
                            </div>
                              <v-select
                              @search="getProducts($event,'ouaka')"
                              @input="CheckSellerProducts($event,action,'product')"
                              label="name"
                              :placeholder='$t("Product name")' 
                              :options="products"
                              v-model="productdata"
                              class="text-gray-600 bg-white w-full -ml-10 pl-8 pr-2 py-1 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500"
                            ></v-select>
                        </div>
                    </div>
                    <div :class="action=='Products' || action==='Revenues' || action==='Processed'?currentUser.role.name!='Seller' && currentUser.type!='Seller'?'pl-1 ww-40':'ww-90 px-1':'ww-3/7'"  v-if="filter && action!='Expeditions'">
                        <div class="rounded-lg border-2 border-gray-200 hover:border-blue-400 w-full focus-within:border-blue-500 focus-within:text-blue-500 transition-all duration-500 relative rounded p-1">
                            <p>  
                                <select id="type" :name="action" autocomplete="type" @change="ChangeDatevalue($event)" v-model="timeserchtype" class="py-2 text-xs text-gray-600 capitalize px-2 cursor-pointer outline-none block h-full w-full">
                                    <option class="bg-white text-gray-600" value="all">All</option>
                                    <option class="bg-white text-gray-600" value="today">Today</option>
                                    <option class="bg-white text-gray-600" value="yesterday">Yesterday</option>
                                    <option class="bg-white text-gray-600" value="thisweek">This week</option>
                                    <option class="bg-white text-gray-600" value="lastweek">Last week</option>
                                    <option class="bg-white text-gray-600" value="thismonth">This Month</option>
                                    <option class="bg-white text-gray-600" value="lastmonth">Last Month</option> 
                                    <option class="bg-white text-gray-600" value="thisyear">This Year</option>
                                    <option class="bg-white text-gray-600" value="betweendate">Between two date</option>
                                </select>
                              </p>
                          </div>
                    </div>
                    <div class="ww-1/7 flex justify-center items-center">
                        <svg v-if="currentUser.role.name=='Seller' && currentUser.type=='Seller' && (action==='Revenues' || action==='Processed' || action==='Products')" :class="loading?'btn-disable':''" :disabled="loading"  @click="removedata(action)" xmlns="http://www.w3.org/2000/svg" class="text-gray-600 fill-current cursor-pointer"  width="20" height="20" viewBox="0 0 24 24"><path d="M5 18c4.667 4.667 12 1.833 12-4.042h-3l5-6 5 6h-3c-1.125 7.98-11.594 11.104-16 4.042zm14-11.984c-4.667-4.667-12-1.834-12 4.041h3l-5 6-5-6h3c1.125-7.979 11.594-11.104 16-4.041z"/></svg>
                        <svg v-else :class="loading?'btn-disable':''" :disabled="loading" @click="removedata(action)" xmlns="http://www.w3.org/2000/svg" class="text-gray-600 fill-current cursor-pointer"  width="20" height="20" viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6 16.538l-4.592-4.548 4.546-4.587-1.416-1.403-4.545 4.589-4.588-4.543-1.405 1.405 4.593 4.552-4.547 4.592 1.405 1.405 4.555-4.596 4.591 4.55 1.403-1.416z"/></svg>
                    </div>
                  </div>
                  <div v-if="filter && timeserchtype=='betweendate'" class="p-1">
                  <!-- <div class="inline-block mt-2 ww-45 pr-1">
                      <div class="border hover:border-blue-400 w-full focus-within:border-blue-500 focus-within:text-blue-500 transition-all duration-500 relative rounded p-1">
                          <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"><p><label for="date" class="bg-white text-gray-700 px-1">From <span class="text-blue-500">*</span></label></p></div>
                          <p>  <input :name="action" v-model="fromDate" class="w-full px-1 py-1 text-gray-700 bg-gray-200 rounded" id="from" type="date"> </p>
                      </div>
                  </div>
                  <div class="inline-block mt-2 ww-45 pr-1">
                      <div class="border hover:border-blue-400 w-full focus-within:border-blue-500 focus-within:text-blue-500 transition-all duration-500 relative rounded p-1">
                          <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"><p><label for="date" class="bg-white text-gray-700 px-1">To <span class="text-blue-500">*</span></label></p></div>
                          <p>  <input :name="action" v-model="toDate" class="w-full px-1 py-1 text-gray-700 bg-gray-200 rounded" id="to" type="date"> </p>
                      </div>
                  </div> -->
                
                  <div class="flex items-center space-x-2 sm:space-x-2">
                        <date-picker placeholder="Range date ..." format="YYYY-MM-DD" type="date" class="w-full-imp  shadow" v-model="fromDate" range></date-picker>
                        <!-- <button @click="FindRecordsBetweenDate()" :disabled="fromDate==null || toDate==null" class="w-full px-2 py-2 text-white font-light tracking-wider bg-gray-900 rounded">Find</button> -->
                        <svg @click="FindRecordsBetweenDate()" :class="!fromDate?'btn-disable':''" :disabled="!fromDate" class="text-gray-600 fill-current cursor-pointer"  width="22" height="22" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M21.172 24l-7.387-7.387c-1.388.874-3.024 1.387-4.785 1.387-4.971 0-9-4.029-9-9s4.029-9 9-9 9 4.029 9 9c0 1.761-.514 3.398-1.387 4.785l7.387 7.387-2.828 2.828zm-12.172-8c3.859 0 7-3.14 7-7s-3.141-7-7-7-7 3.14-7 7 3.141 7 7 7zm-3-8c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm3 0c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm3 0c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1z"/></svg>
                    </div>  
                  </div>
            </div>
            <hr> 
            <div class="relative">
                 <loading :active.sync="loading" :is-full-page="fullPage"></loading>
                 <div class="px-2">
                     <div class="d-flex align-items-center">
                           <vue-easy-pie-chart :percent="parseInt(datas.rate)"
                              :bar-color="'#3b5998'"
                              :track-color="'rgba(0, 0, 0, 0.08)'"
                              :scale-color="'false'"
                              :line-cap="'easeOutBounce'"
                              :line-width="10"
                              :scale-length="1"
                              :rotate="0"
                              :size="70"
                              :font-size="'15px'">
                            </vue-easy-pie-chart>
                            <div  class="ms-3">
                                <h6  class="mb-0">
                                  <svg v-if="action && action === 'OrdersSummary'" class="fill-current inline-block" width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M12.666 22.908l-2.787-5.379-1.634.8c-1.773.86-5.383-6.191-3.649-7.12l1.648-.812-2.764-5.397-1.667.823c-5.702 2.972 3.351 20.569 9.183 17.903.096-.043 1.664-.815 1.67-.818zm4.902-2.603l1.041 1.71c-1.019.674-2.147 1.197-3.351 1.537l-.934-1.824c1.175-.28 2.27-.768 3.244-1.423zm2.922-3.022l1.882.752c-.678 1.162-1.546 2.199-2.56 3.071l-1.057-1.736c.668-.612 1.253-1.315 1.735-2.087zm.699-1.336c.522-1.211.811-2.546.811-3.947 0-5.519-4.481-10-10-10-2.497 0-4.781.917-6.534 2.432l-.933-1.824c2.05-1.632 4.645-2.608 7.467-2.608 6.623 0 12 5.377 12 12 0 1.664-.34 3.249-.953 4.69l-1.858-.743zm-3.511-.947h-1.438v-1.55h-2.88v-.989l2.46-3.96h1.858v3.81h.78v1.139h-.78v1.55zm-5.07 0h-4.608v-.919l.84-.76c1.42-1.27 2.11-2 2.13-2.761 0-.53-.32-.95-1.07-.95-.56 0-1.05.28-1.39.54l-.43-1.089c.49-.37 1.25-.67 2.13-.67 1.469 0 2.278.859 2.278 2.039 0 1.091-.789 1.961-1.728 2.801l-.6.5v.02h2.448v1.249zm3.632-2.689v-1.44c0-.391.02-.791.05-1.211h-.04c-.21.42-.38.8-.6 1.211l-.87 1.42v.02h1.46z"/></svg> 
                                  <svg v-else class="fill-current inline-block"  width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M7.919 17.377l-4.869-13.377h-2.05c-.266 0-.52-.105-.707-.293-.188-.187-.293-.442-.293-.707 0-.552.447-1 1-1h3.45l5.469 15.025c.841.101 1.59.5 2.139 1.088l11.258-4.097.684 1.879-11.049 4.021c.032.19.049.385.049.584 0 1.932-1.569 3.5-3.5 3.5-1.932 0-3.5-1.568-3.5-3.5 0-1.363.781-2.545 1.919-3.123zm1.581 1.811c.724 0 1.312.588 1.312 1.312 0 .724-.588 1.313-1.312 1.313-.725 0-1.313-.589-1.313-1.313s.588-1.312 1.313-1.312zm13.314-6.512l-11.418 4.156-2.736-7.518 11.418-4.156 2.736 7.518zm-8.71-3.215l-2.88 1.048 1.368 3.759 7.659-2.787-1.368-3.759-2.9 1.055.684 1.879-1.879.685-.684-1.88zm2.672-4.165l-8.458 3.078-1.927-5.296 8.457-3.078 1.928 5.296zm-3.123-2.733l-4.699 1.711.56 1.537 4.698-1.71-.559-1.538z"/></svg>
                                  {{statSubtitleRate}}
                                </h6>
                                 <small class="mb-0" :class="Math.sign(datas.since) === 1?'text-success':'text-warnred'">
                                     {{Math.sign(datas.since) === 1?'+':''}}{{datas.since||0}}%<svg v-if="Math.sign(datas.since) === 1"  class="fill-current transform-1 inline-block" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 3l12 18h-24z"/></svg>
                                    <svg v-else xmlns="http://www.w3.org/2000/svg" class="fill-current transform-1 inline-block" width="24" height="24" viewBox="0 0 24 24"><path d="M12 21l-12-18h24z"/></svg>
                                         {{$t('Since last')}} {{$t(datas.field)}}
                                 </small>
                            </div>
                            <div class="ms-auto fs-1 text-facebook">
                                 <div class="row m-0 row-cols-1 row-cols-md-3"> 
                                    <div v-for="(item, index) in datas.traits" :key="index" :class="index===datas.traits.length-1?'':'border-end'" class="col"> 
                                        <div  :id="(action+'-'+item.name).toLowerCase()" class="center-chart"></div>
                                    </div>
                                 </div>
                            </div> 
                        </div>
                        <hr> 
                 </div>
                
                <div  class="mt-2">
                    <div  class="chart-container-4">
                       <!-- <canvas id="chart5"></canvas> -->
                        <div :id="'chart-'+action"></div>
                    </div>
                </div> 
                <ul  class="list-group list-group-flush mt-2">
                    <li v-for="(item, index) in datas.dataOrders" :key="index"  class="list-group-item font-semibold d-flex bg-transparent justify-content-between align-items-center">
                      {{item.name}} <small v-if="item.name === 'Pending'">(Fresh: {{ item.PendingFresh }}, Unreached: {{ item.PendingUnrached }}, Remind: {{ item.PendingRemind }})</small>
                      <span :class="'bg-'+item.name.toLowerCase()"  class="badge bg-success rounded-pill">{{item.count}}</span>
                    </li>
                </ul>
            </div> 
    </div>       
</div>
</template>

<script>
import VueEasyPieChart from 'vue-easy-pie-chart'
import 'vue-easy-pie-chart/dist/vue-easy-pie-chart.css'
export default {
  components:{ VueEasyPieChart },
  name: "card-v2",
  props: {
    statSubtitle: {default: "Traffic",},
    statSubtitleRate: {default: "Rate",},
    loading:{required:false},
    timeserch:{},
    from:{},
    to:{},
    filter: {default: false,},
    filterseller: {default: false,},
    id:{type:String},
    action:{type:String},
    currency: {default:false},
    count:{type: Number,default:0},
    datas:{type:Object},
    dataChart:{type:Array},
    currentUser:{type:Object},
    warhouse:{type:Object},
    sellers:{type:Array},
    products:{type:Array},
    seller:{},
    product:{},
    gardien:{ type: String,default() {return ''}},
    statIconColor: {
      type: String,
      default: "bg-red-500",
    },
  },
  data() {
    return {
      fullPage: false,
      timeserchtype:this.timeserch,
      myChart: "",
      myChartTrait: ["",""],
      fromDate:this.from,
      toDate:this.to,
      sellerdata:this.seller,
      productdata:this.product,
    };
  },
 watch: {
    dataChart: function () {
      if (this.myChart && this.myChart != null) this.myChart.destroy();
      if (this.myChartTrait.length>0 && this.myChartTrait[0]) {
        for (let i in this.myChartTrait) {
              this.myChartTrait[i].destroy();
        }
      }
      this.drawChart(this.dataChart);
      this.myChart.render();
       for (let i in this.myChartTrait) {
        this.myChartTrait[i].render();
      }
      
    },
    'seller': function () {
       console.log('seller------',this.seller)
       this.sellerdata=this.seller;
    },
   'product': function () {
      console.log('product------',this.product)
      this.productdata=this.product;
   },
   'timeserch': function () {
       console.log('timeserch------',this.timeserch)
       this.timeserchtype=this.timeserch;
    },
  },
  async mounted(){
     await this.drawChart(this.dataChart);
     this.myChart.render();
    if (this.myChartTrait.length>0 && this.myChartTrait[0]) {
        for (let i in this.myChartTrait) {
             this.myChartTrait[i].render();
        }
    }   
     if(this.currentUser && this.currentUser.role && this.currentUser.role.name=='Seller' && this.currentUser.type=='Seller') this.sellerdata=this.currentUser;
  },
   methods: {
     getPercentege(countStatus,total){
         return total?Math.round((countStatus * 100) /  total):0;
     },
    FindRecordsBetweenDate: function (val) {
         this.$emit("FindRecordsBetweenDate", this.action,this.fromDate.map( el => this.$moment(el).format('yyyy-MM-DD')),this.toDate);
    },
    getUsersDebance: function (name) {
        this.$emit("getUsersDebance", name);
     }, 
    getProducts: function (name) {
        this.$emit("getProducts", name);
     }, 
    async CheckSellerProducts(seller,action,model){
       this.$emit("CheckSellerProducts", seller,action,model);
    },
    removedata: function (val) {
        this.$emit("removedata", this.action);
     }, 
    drawChart(chartData) {
        //Chart Traits
         for (let i in this.datas.traits) {
           let traitdata=this.datas.traits[i].count;
           this.myChartTrait[i]= new ApexCharts(document.getElementById((this.action+'-'+this.datas.traits[i].name).toLowerCase()), {
                    chart: {
                        height: 140,
                        width:100,
                        type: "radialBar",
                        toolbar: {
                            show: !1
                        }
                    },
                    plotOptions: {
                        radialBar: {
                            hollow: {
                                margin: 0,
                                size: "78%",
                                background: this.datas.traits[i].background,
                                image: void 0,
                                imageOffsetX: 0,
                                imageOffsetY: 0,
                                position: "front",
                                dropShadow: {
                                    enabled: !1,
                                    top: 0,
                                    left: 0,
                                    blur: 4,
                                    color: "rgba(0, 169, 255, 0.85)",
                                    opacity: .65
                                }
                            },
                            track: {
                                margin: 0,
                                dropShadow: {
                                    enabled: !1,
                                    top: 0,
                                    left: 0,
                                    blur: 4,
                                    color: "rgba(0, 169, 255, 0.85)",
                                    opacity: .65
                                }
                            },
                            dataLabels: {
                                showOn: "always",
                                name: {
                                    offsetY: -10,
                                    show: !0,
                                    color: "#6c757d",
                                    fontSize: "12px"
                                },
                                value: {
                                    formatter: function(e) {
                                        return traitdata+ " ("+e + "%)";
                                    },
                                    color: "#000",
                                    fontSize: "12px",
                                    show: !0,
                                    offsetY: -5
                                }
                            }
                        }
                    },
                    fill: {
                        type: "gradient",
                        gradient: {
                            shade: "light",
                            type: "horizontal",
                            shadeIntensity: .5,
                            gradientToColors: [this.datas.traits[i].color],
                            inverseColors: !1,
                            opacityFrom: 1,
                            opacityTo: 1,
                            stops: [0, 100]
                        }
                    },
                    colors: [this.datas.traits[i].color],
                    series: [this.datas.traits[i].percent],
                    stroke: {
                        lineCap: "round",
                        width: "5"
                    },
                    labels: [this.datas.traits[i].name]
                });
         }
         //Chart 
        var ctx = document.getElementById("chart5");
        // this.myChart = new Chart(ctx, {
        //         type: 'bar',
        //         data: {
        //             labels: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
        //             datasets: [{
        //             label: 'Total Earning',
        //             data: [39, 19, 25, 16, 31, 39, 23, 20, 23, 18, 15, 20],
        //             backgroundColor: "#04b35a",
        //             barPercentage: .4,
        //             },{
        //             label: 'Total Sales',
        //             data: [27, 12, 26, 15, 21, 27, 13, 19, 32, 22, 18, 30],
        //             backgroundColor: "rgba(4, 179, 90, 0.35)",
        //             barPercentage: .4,
        //             }]
        //         },
        //         options: {
        //             maintainAspectRatio: false,
        //             legend: {
        //                 display: false,
        //                 position: 'bottom',
        //                 labels: {
        //                 fontColor: '#ddd',  
        //                 boxWidth:13
        //             }
        //             },
        //             tooltips: {
        //                 enabled:true,
        //                 displayColors:false,
        //             },	
        //             hover: {
        //                 animationDuration: 1
        //             },
        //             animation: {
        //                 duration: 1,
        //                 onComplete: function () {
        //                     var chartInstance = this.chart,
        //                         ctx = chartInstance.ctx;
        //                         ctx.textAlign = 'center';
        //                         ctx.fillStyle = "rgba(0, 0, 0, 1)";
        //                         ctx.textBaseline = 'bottom';
        //                         // Loop through each data in the datasets
        //                         this.data.datasets.forEach(function (dataset, i) {
        //                             var meta = chartInstance.controller.getDatasetMeta(i);
        //                             meta.data.forEach(function (bar, index) {
        //                                 var data = dataset.data[index];
        //                                 ctx.fillText(data, bar._model.x, bar._model.y - 5);
        //                             });
        //                         });
        //                     }
        //             },
        //             scales: {
        //                 xAxes: [{
        //                     stacked: true,
        //                     display: true,
        //                     gridLines: true,
        //                     ticks: {
        //                         padding: 5
        //                     }
        //                 }],
        //                 yAxes: [{
        //                     stacked: true,
        //                     display: false,
        //                     gridLines: true
        //                 }]
        //             }

        //         }
                
        // });
        var ctx1 = document.getElementById("chart-"+this.action);
         if(this.action=='OrdersSummary'){
          this.myChart = new ApexCharts(ctx1,{
                  series: chartData.map(function(item) { delete item.color; return item; }),
                  chart: {
                      foreColor: "#9ba7b2",
                      type: "bar",
                      height: 237,
                      stacked: true,
                      toolbar: {
                        show: false
                      },
                      zoom: {
                          enabled: !1
                      },
                      dropShadow: {
                          enabled: !0,
                          top: 3,
                          left: 14,
                          blur: 4,
                          opacity: .12,
                          color: "#0d6efd"
                      },
                      sparkline: {
                          enabled: !1
                      }
                  },
                  markers: {
                      size: 0,
                      colors: ["#04b35a", '#f1086e'],
                      strokeColors: "#fff",
                      strokeWidth: 3,
                      hover: {
                          size: 8
                      }
                  },
                  plotOptions: {
                      bar: {
                          horizontal: !1,
                          columnWidth: "40%",
                          endingShape: "rounded"
                      }
                  },
                  dataLabels: {
                      enabled: true,
                      style: {
                          fontSize: '12px',
                          fontWeight: 'bold',
                      },
                  },
                  stroke: {
                      width: 3.5,
                      curve: 'smooth'
                  },
                  colors: ["#04b35a", '#f1086e'],
                  xaxis: {
                      categories: this.datas.label
                  },
                  yaxis: {
                    show:false
                  },
                  fill: {
                      opacity: 1
                  },
                  tooltip: {
                      theme: "dark",
                      fixed: {
                          enabled: !1
                      },
                      x: {
                          show: !0
                      },
                      y: {
                          formatter: function(e) {
                              return " " + e + " "
                          }
                      },
                      marker: {
                          show: !1
                      }
                  }
          });
        }else{
           this.myChart = new ApexCharts(ctx1,{
              series: chartData.map(function(item) { return item.count; }),
              chart: {
                foreColor: '#9ba7b2',
                height: 170,
                type: 'pie',
              },
              colors: chartData.map(function(item) { return item.color; }),
              labels: chartData.map(function(item) { return item.name; }),
              responsive: [{
                breakpoint: 480,
                options: {
                  chart: {
                    height: 200
                  },
                  legend: {
                    position: 'bottom'
                  }
                }
              }]
            });
            console.log(this.statSubtitleRate,"statSubtitleRate1")
        }
    },
    ChangeDatevalue: function (val) { 
      // if(val.target.name=="ConfirmationRate")  this.$emit("ChangeDateConfirmationRate", val);
      // if(val.target.name=="Delivery") this.$emit("ChangeDateConfirmationDelevry", val);
      this.$emit("ChangeDatevalue", val);
    },
    Refrechdata: function (val) { 
        this.$emit("Refrechdata", val);
    },
    //  FindRecords: function () {
    //   this.$emit("FindRecords", this.action,this.fromDate,this.toDate);
    // },
   }
};
</script>
<style scoped>
.bg-pending{
    background-color: #15ca20 !important;
}
.bg-outofstock{
    background-color:#c5850f !important;
}
.bg-orange{
    background-color: #c115ca !important;
}
.bg-unreached{
    background-color: #7a1ae7 !important;
}
.bg-to{
  background-color: #ffc108 !important;
}
.bg-prepared{
  background-color: #0ca7ce !important;
}
.bg-cancelled{
  background-color: #e70f22 !important;
}
.bg-refused{
  background-color: #e70fdb !important;
}
.bg-prepared{
  background-color: #0ca7ce !important;
}
.bg-shipped{
  background-color: #6d757d !important;
}
.bg-refued{
  background-color: #951180 !important;
}
.bg-return,.bg-double{
  background-color: #3b0460 !important;
}
.bg-reprogrammer{
  background-color: #8d4b07 !important;
}

.fz-17{
    font-size: 16px;
}
.img-40{
    width: 40px;
}
h6 {
  margin-top: 0;
  font-weight: 500;
  line-height: 1.2;
}
.w_chart {
  position: relative;
  display: inline-block;
  width: 65px !important;
  height: 65px !important;
  text-align: center;
  color: #32393f;
}
.w_percent {
  display: inline-block;
  line-height: 65px !important;
  z-index: 2;
}
.w_percent::after {
  content: "%";
  margin-left: .1em;
  font-size: .8em;
}
.w_chart canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 65px !important;
  height: 65px !important;
}
.ms-3 {
  margin-left: 1rem !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
small, .small {
  font-size: .875em;
}
.text-facebook {
  color: #3b5998 !important;
}
.text-warnred{
  color: #ca1556;
}
.text-success {
    color: #15ca20!important;
}
.ww-3\/7{
   width: 50%;
}
.ww-1\/7{
   width: 7%;
}
.ww-40{
    width: 48%;
}
.ww-45{
    width: 48%;
}
.ww-90{
    width: 95%;
}
.ww-10{
    width: 10%;
}
.ww-3{
    width: 4%;
}
.filter-dash{
    background-color: #f8f9fa;
    transition: all .2s;
    border: 1px solid #dee2e6!important;
}
.z-1 {
    z-index: 1;
}
.transform-1{
  transform: scale(0.6);
}
.radius-10 {
  border-radius: 10px;
}
.card {
  margin-bottom: 1.5rem;
  box-shadow: 0 2px 6px #dadafda6,0 2px 6px #ceceee8a;
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid rgba(0,0,0,.125);
}
.card-body {
  flex: 1 1 auto;
  padding: 1rem;
}
.align-items-center {
  align-items: center !important;
}
.d-flex {
  display: flex !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.font-22 {
  font-size: 22px;
}
.ms-auto {
  margin-left: auto !important;
}
hr:not([size]) {
  height: 1px;
}
hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: .25;
}
.center-chart{
    align-items: center;
    justify-content: center;
    display: flex;
}
.m-0 {
  margin: 0 !important;
}

.row {
  display: flex;
  flex-wrap: wrap;
}
.border-end {
  border-right: 2px dashed #bab7b7 !important;
}

@media (min-width: 768px){
.row-cols-md-3 > * {
  width: 20%;
}
}
/* .row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
} */

/* .row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: 5px;
  padding-left: 5px;
} */
.mt-5 {
  margin-top: 3rem !important;
}

.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.list-group-flush {
  border-radius: 0;
}
.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: .25rem;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
 border-bottom: none;
}
.bg-transparent {
  background-color: transparent !important;
}
.align-items-center {
  align-items: center !important;
}
.justify-content-between {
  justify-content: space-between !important;
}
.d-flex {
  display: flex !important;
}
.list-group-item {
  position: relative;
  display: block;
  padding: .5rem 1rem;
  color: #212529;
  background-color: #fff;
  border: 1px solid rgba(0,0,0,.125);
  font-size: 15px;
}
.rounded-pill {
  border-radius: 50rem !important;
}
.bg-success {
  background-color: #15ca20;
}
.bg-danger {
  background-color: #fd3550
}
.bg-primary {
  background-color: #008cff 
}
.bg-warning {
  background-color: #ffc107
}
.text-dark {
  color: #212529
}
.badge {
  display: inline-block;
  padding: .35em .65em;
  font-size: .75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
}
</style>