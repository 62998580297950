<template>
  <div>
    <div
      class="flex-grow flex flex-col m-2 p-6 bg-white border-t border-b sm:rounded sm:border shadow overflow-hidden"
    >
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <h3 class="font-semibold text-base text-gray-800">
            {{ title }}
          </h3>
        </div>
      </div>
      <div class="flex flex-wrap items-center mt-5">
        <div
          v-if="userType == 'Admin' || userType == 'CEO'"
          class="relative w-full px-4 max-w-full flex-grow flex-1"
        >
          <div
            class="border h-10 hover:border-blue-400 focus-within:border-blue-500 focus-within:text-blue-500 transition-all duration-500 relative rounded p-1"
          >
            <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
              <p>
                <label for="category" class="bg-white text-gray-700 px-1"
                  >{{$t('seller')}} : <span :class="$colors.required">*</span></label
                >
              </p>
            </div>
            <p>
              <v-select
                v-model="timeserchseller"
                :options="sellers"
                label="fullName"
                @input="ChangeSellerConfirmation"
              ></v-select>
            </p>
          </div>
        </div>
        <div class="relative w-full px-4 max-w-full flex-grow flex-1 text-right">
          <div
            class="border hover:border-blue-400 w-full focus-within:border-blue-500 focus-within:text-blue-500 transition-all duration-500 relative rounded p-1"
          >
            <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
              <p>
                <label for="status" class="bg-white text-gray-700 px-1"
                  >{{$t('date')}} : <span :class="$colors.required">*</span></label
                >
              </p>
            </div>
            <p>
              <select
                id="type"
                autocomplete="type"
                @change="ChangeTypeDateConfirmation($event)"
                v-model="timeserchtype"
                class="py-2 text-xs capitalize px-2 outline-none block h-full w-full bg-white"
              >
                >
               <option class="bg-white text-gray-600" value="today">
                  {{$t('today')}}
                </option>
                <option class="bg-white text-gray-600" value="thisweek">
                  {{$t('this_week')}}
                </option>
                <option class="bg-white text-gray-600" value="lastweek">
                  {{$t('last_week')}}
                </option>
                <option class="bg-white text-gray-600" value="thismonth">
                  {{$t('this_month')}}
                </option>
                <option class="bg-white text-gray-600" value="lastmonth">
                  {{$t('last_month')}}
                </option>
                <option class="bg-white text-gray-600" value="thisyear">
                  {{$t('this_year')}}
                </option>
                <option value="betweendate" class="bg-white text-gray-600"> {{$t('between_two_date')}} </option>
              </select>
            </p>
          </div>
        </div>
      </div>
      <div v-if="filter && timeserchtype=='betweendate'" class="relative w-full px-4 max-w-full flex-grow flex-1 text-right">
          <div  class="inline-block mt-4">
              <div class="inline-block mt-2 w-2/5 pr-1">
                <div class="border hover:border-blue-400 w-full focus-within:border-blue-500 focus-within:text-blue-500 transition-all duration-500 relative rounded p-1">
                  <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"><p><label for="date" class="bg-white text-gray-700 px-1">From <span class="text-blue-500">*</span></label></p></div>
                    <p>  <input  v-model="fromDate" class="w-full px-1 py-1 text-gray-700 bg-gray-200 rounded" id="from" type="date"> </p>
                </div>
              </div>
                <div class="inline-block mt-2 w-2/5 pr-1">
                  <div class="border hover:border-blue-400 w-full focus-within:border-blue-500 focus-within:text-blue-500 transition-all duration-500 relative rounded p-1">
                  <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"><p><label for="date" class="bg-white text-gray-700 px-1">To <span class="text-blue-500">*</span></label></p></div>
                    <p>  <input  v-model="toDate" class="w-full px-1 py-1 text-gray-700 bg-gray-200 rounded" id="to" type="date"> </p>
                </div>
              </div>
              <div class="inline-block mt-2 w-1/5 pr-1">
                  <button @click="FindRecordconfirmations()"  :disabled="fromDate==null || toDate==null" class="w-full px-2 py-2 text-white font-light tracking-wider bg-gray-900 rounded">Find</button>
              </div>
          </div>
        </div>
      <canvas :id="id" style="height: 300px"></canvas>
    </div>
  </div>
</template>



<script>
import Chart from "chart.js";
//import ChartDataLabels from "chartjs-plugin-datalabels";
export default {
  props: [
    "chartData",
    "options",
    "sellers",
    "Labels",
    "labelstring",
    "timeserch",
    "timeserchsellerc",
    "id",
    "title",
    "type",
    "userType",
    "filter",
    "count",
    "from",
    "to"
  ],
  data() {
    return {
      myChart: "",
      timeserchtype: this.timeserch,
      timeserchseller: this.timeserchseller,
      fromDate:this.from,
      toDate:this.to,
    };
  },
  async mounted() {
    await this.drawChart(this.chartData, this.labelstring);
  },
  methods: {
    FindRecordconfirmations: function () {
      this.$emit("FindRecordconfirmations",this.fromDate,this.toDate);
    },
    ChangeTypeDateConfirmation: function (val) {
      this.$emit("ChangeTypeDateConfirmation", val);
    },
    ChangeSellerConfirmation: function (val) {
      this.$emit("ChangeSellerConfirmation", val);
    },
    drawChart(chartData, labelstring) {
      var ctx = document.getElementById(this.id);
      this.myChart = new Chart(ctx, {
        type: "pie",
        showTooltips: true,
        data: {
          labels: ["Pending ("+this.chartData[0]+")", "Confirmed ("+this.chartData[1]+")", "Cancelled ("+this.chartData[2]+")"],
          datasets: [
            {
              label: "Total Orders",
              data: chartData,
              backgroundColor: [
                "#ff6384",
                "#79D1CF",
                "tan",
              ],
              borderColor: ["#ff6384", "#79D1CF","tan"],
              borderWidth: 1,
              weight: 2,
            },
          ],
        },
         options: {
          responsive: true,
          tooltips: {
            callbacks: {
                label: function(tooltipItem, data) {
                  var allData = data.datasets[tooltipItem.datasetIndex].data;
                  var tooltipLabel = data.labels[tooltipItem.index];
                  var tooltipData = allData[tooltipItem.index];
                  var total = 0;
                  for (var i in allData) {
                    total += allData[i];
                  }
                  var tooltipPercentage = Math.round((tooltipData / total) * 100);
                  return tooltipLabel + ': ' + tooltipData + ' (' + tooltipPercentage + '%)';
                }
              }
          },
           title: {
            display: true,
            text: 'Total Orders: '+this.count,
            position: 'top',
            fontSize: 16,
            fontColor: '#111',
            padding: 20
        },
        legend: {
            display: true,
            position: 'bottom',
            labels: {
                boxWidth: 20,
                fontColor: '#111',
                padding: 15
            }
        },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        },
      });
    },
  },
  watch: {
    chartData: function () {
      if (this.myChart != null) {
        this.myChart.destroy();
      }
      this.drawChart(this.chartData, this.labelstring);
    },
    labelString: function () {
      if (this.myChart != null) {
        this.myChart.destroy();
      }
      this.drawChart(this.chartData, this.labelstring);
    },
  },
};
</script>
<style scoped>
body {
  font-family: "IBM Plex Mono", sans-serif;
}
[x-cloak] {
  display: none;
}
.vs__dropdown-toggle {
  border: none;
}
.line {
  background: repeating-linear-gradient(
    to bottom,
    #eee,
    #eee 1px,
    #fff 1px,
    #fff 8%
  );
}
.tick {
  background: repeating-linear-gradient(
    to right,
    #eee,
    #eee 1px,
    #fff 1px,
    #fff 5%
  );
}
</style>