<template>
  <div
    :class="min_height?'min-height-231':''" class="relative flex flex-col min-w-0 break-words bg-white rounded mb-6 xl:mb-0 shadow-lg"
  >
   <loading :active.sync="loading" :is-full-page="fullPage"></loading>
    <div class="flex-auto p-4 flex-col flex justify-between">
      <div class="flex flex-wrap">
        <div class="relative w-full pr-4 max-w-full flex-grow flex-1">
          <h5 class="text-gray-500 uppercase font-bold text-xs">
            {{ statSubtitle }} <span v-if="count">:  {{ count }} <span class="font-semibold">({{countText}})</span></span>
          </h5>
          <span class="font-semibold text-xl text-gray-800"><sup v-if="currency" class="text-black">{{currencyW}}</sup> {{ statTitle }}  </span>
          <h5  v-if="statSubtitle1 && userType == 'CEO'" class="text-gray-500 uppercase font-bold text-xs">
            {{ statSubtitle1 }} <span class="font-semibold">(-5%)</span>
          </h5>
           <span v-if="statSubtitle1 && userType == 'CEO'" class="font-semibold text-xl text-gray-800"><sup v-if="currency" class="text-black">{{currencyW}}</sup> {{ (5*statTitle/100).toFixed(2) }}  </span>
          <div  class="mt-2">
            <h5 v-for="(item, key) in datas" :key="key"  class="text-gray-800  uppercase font-bold text-xs">
                <i style="font-size: 10px;" class="material-icons">fiber_manual_record</i> Total {{ item.name||item.status }} : <span class="font-semibold text-xs">({{item.count}} orders)</span>
            </h5>
          </div> 
          <!-- <div v-if="rate" class="flex items-center mb-2 mt-2"><span class="inline-flex bg-green-200 rounded-full mr-2 p-1"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" class="icon fill-current text-green-600 h-4 w-4"><path d="M20 22H4a2 2 0 0 1-2-2v-8c0-1.1.9-2 2-2h4V8c0-1.1.9-2 2-2h4V4c0-1.1.9-2 2-2h4a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2zM14 8h-4v12h4V8zm-6 4H4v8h4v-8zm8-8v16h4V4h-4z" class="heroicon-ui"></path></svg></span> 
            <span class="text-grey-darker">{{titleRate}} {{rate}} %</span>
					</div> -->
          <div v-if="datasAcounting" class="mt-2 mb-2">
            <h5 v-for="(item, key) in datasAcounting" :key="key"  class="text-gray-800  uppercase font-bold text-xs">
                <i style="font-size: 10px;" class="material-icons">fiber_manual_record</i> {{ item.name }} : <span class="font-semibold text-xs"><sup class="text-black">{{currencyW}}</sup> {{item.sum}} </span><span class="font-semibold  text-xs text-gray-800">({{item.count}} orders)</span>
            </h5>
          </div>
        </div>
        <div class="relative w-auto pl-4 flex-initial">
          <div
            class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 shadow-lg rounded-full"
            :class="[statIconColor]"
          >
            <i class="material-icons">{{ statIconName }}</i>
          </div>
        </div>
      </div>
     <p v-if="!filter" class="text-sm text-gray-500 mt-4">
        <span class="mr-2" :class="[statPercentColor]">
          <i
            :class="[
              statArrow === 'up' ? `fas fa-arrow-up` : `fas fa-arrow-down`,
            ]"
          ></i>
          {{ statPercent }}
        </span>
        <span class="whitespace-no-wrap">{{ statDescripiron }}</span>
      </p> 
      <div class="flex flex-no-wrap items-center mt-3">
      <div v-if="filter" class="relative w-1/2 flex">
              <div class="border hover:border-blue-400 w-full focus-within:border-blue-500 focus-within:text-blue-500 transition-all duration-500 relative rounded p-1">
                <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                  <p>
                    <label for="status" class="bg-white text-gray-700 px-1"
                      >Date : <span :class="$colors.required">*</span></label
                    >
                  </p>
                </div>
              <p>  
              <select 
                    id="type"
                    :name="action"
                    autocomplete="type"
                    @change="ChangeTypeDateRevenu($event)"
                    v-model="timeserchtype"
                    class="py-2 text-xs capitalize px-2 outline-none block h-full w-full bg-white"
                  >
                    >
                    <option class="bg-white text-gray-600" value="all">
                      All
                    </option>
                     <option class="bg-white text-gray-600" value="betweendate">
                      Between two date
                    </option>
                    <!-- <option class="bg-white text-gray-600" value="thisweek">
                      This Week
                    </option>
                    <option class="bg-white text-gray-600" value="lastweek">
                      Last Week
                    </option>
                    <option class="bg-white text-gray-600" value="thismonth">
                      This Month
                    </option>
                    <option class="bg-white text-gray-600" value="lastmonth">
                      Last Month
                    </option> -->
                  </select>
                </p>
              </div>
      </div>
      <div v-if="timeserchsellerselect && (userType == 'Admin' || userType == 'CEO')" class="relative ml-2 w-1/2 flex">
              <div class="border hover:border-blue-400 w-full focus-within:border-blue-500 focus-within:text-blue-500 transition-all duration-500 relative rounded p-1">
                <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                  <p>
                    <label for="status" class="bg-white text-gray-700 px-1"
                      >{{$t('seller')}} : <span :class="$colors.required">*</span></label
                    >
                  </p>
                </div>
             <p>
              <v-select
                v-model="timeserchsellerselect"
                :options="sellers"
                label="fullName"
                @input="ChangeSeller"
              ></v-select>
            </p>
              </div>
      </div>
      </div>
      <div v-if="filter && timeserchtype=='betweendate'" class="inline-block">
        <div class="inline-block mt-2 w-2/5 pr-1">
          <div class="border hover:border-blue-400 w-full focus-within:border-blue-500 focus-within:text-blue-500 transition-all duration-500 relative rounded p-1">
            <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"><p><label for="date" class="bg-white text-gray-700 px-1">From <span class="text-blue-500">*</span></label></p></div>
              <p>  <input :name="action" v-model="fromDate" class="w-full px-1 py-1 text-gray-700 bg-gray-200 rounded" id="from" type="date"> </p>
          </div>
        </div>
          <div class="inline-block mt-2 w-2/5 pr-1">
             <div class="border hover:border-blue-400 w-full focus-within:border-blue-500 focus-within:text-blue-500 transition-all duration-500 relative rounded p-1">
            <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"><p><label for="date" class="bg-white text-gray-700 px-1">To <span class="text-blue-500">*</span></label></p></div>
              <p>  <input :name="action" v-model="toDate" class="w-full px-1 py-1 text-gray-700 bg-gray-200 rounded" id="to" type="date"> </p>
          </div>
        </div>
         <div class="inline-block mt-2 w-1/5 pr-1">
            <button @click="FindRecords()"  :disabled="fromDate==null || toDate==null" class="w-full px-2 py-2 text-white font-light tracking-wider bg-gray-900 rounded">Find</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "card-stats",
  props: {
    statSubtitle: {
      default: "Traffic",
    },
    statSubtitle1: {},
    loading:{required:false},
    timeserch:{},
    userType:{},
    user:{},
    timeserchseller:{},
    titleRate:{},
    from:{},
    to:{},
    filter: {
      default: false,
    },
    min_height :{
      default: false,
    },
    statTitle: {},
    action: {},
    statArrow: {
      default: "up",
      validator: function (value) {
        // The value must match one of these strings
        return ["up", "down"].indexOf(value) !== -1;
      },
    },
    statPercent: {},
    // can be any of the text color utilities
    // from tailwindcss
    statPercentColor: {
      default: "text-green-500",
    },
    statDescripiron: {},
    currency: {default:false},
    count:{type: Number,default:0},
    datas:{type:Array},
    sellers:{},
    datasAcounting:{type:Array},
    countText:{},
    rate:{},
    statIconName: {
      default: "far fa-chart-bar",
    },
    // can be any of the background color utilities
    // from tailwindcss
    statIconColor: {
      type: String,
      default: "bg-red-500",
    },
  },
    data() {
    return {
      timeserchtype:this.timeserch,
      fromDate:this.from,
      toDate:this.to,
      timeserchsellerselect: this.timeserchseller,
      fullPage: false,
      currencyW:"",
    };
  },
  async mounted() {
    await this.getCurrency();
  },
    computed: {
    warhouseSelected(){
      return this.$store.getters["wharhouse/warhouseSelected"]({user:_.pick(this.user,['countries'])})
    },
  },
   watch: {
      'warhouseSelected': async function(oldVal, newVal) {
          await this.getCurrency();
           console.log('time search____',this.timeserchtype)
      }
  },
   methods: {
    async getCurrency(){
      await this.$store.dispatch('wharhouse/getCurrency').then((res) => {
            this.currencyW=res;
        })
    },
    ChangeTypeDateRevenu: function (val) {
      this.$emit("ChangeTypeDateRevenu", val);
    },
     FindRecords: function () {
      this.$emit("FindRecords", this.action,this.fromDate,this.toDate);
    },
     ChangeSeller: function (val) {
      this.$emit("ChangeSeller", val);
    },
   }
};
</script>
<style scoped>
.text-grey-darker{
  color: #596a73;
    font-weight: 600;
}
.min-height-231{
  min-height: 231px;
}
.bottom_cart{
   position: absolute;
    bottom: 10px;
    right: 17px
}
</style>
