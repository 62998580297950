<template>
  <div v-if="currentUser && currentUser.type && currentUser.type && currentUser.role && getPermission('dashboard') && getPermission('analytics')">
    <div class="flex flex-wrap mt-5">
      <div v-if="currentUser && currentUser.type && (currentUser.type=='CEO' || currentUser.type=='Seller')" class="w-full lg:w-1/2 md:w-1/2 xl:w-1/3  px-4">
        <card-v1 statSubtitle="Total Revenues" filter="true" filterseller="true" action="Revenues"  
           @ChangeDatevalue="ChangeTypeDateRevenuHeader"  @FindRecordsBetweenDate="FindRecordsHeader" @getUsersDebance="getUsersDebance"  @CheckSellerProducts="CheckSellerProducts" @removedata="removedata"
            :loading="loadingrevenues"
            color="#17a00e"
            cl="success"
            :currency="idWharhouse?idWharhouse.currency:''"
            :datas="dataRevenues"
            :dataChart="dataRevenues.data"
            :sellers="sellers"
            :timeserch="timeserchrevenues"
            :seller="seller_revenues"
            :from="from_revenues"
            :to="to_revenues"
            :currentUser="currentUser"/>
      </div>
      <div v-if="currentUser && currentUser.type && (currentUser.type=='CEO' || currentUser.type=='Seller')" class="w-full lg:w-1/2 md:w-1/2 xl:w-1/3  px-4">
        <card-v1 :statSubtitle="$t('Revenues to pay')"  filter="true"  filterseller="true" action="RevenuesPay" 
           @ChangeDatevalue="ChangeTypeDateRevenuHeader"  @FindRecordsBetweenDate="FindRecordsHeader" @getUsersDebance="getUsersDebance" @getProducts="getProducts"  @CheckSellerProducts="CheckSellerProducts" @removedata="removedata"
            :loading="loadingrevenuespay"
             color="#ffc10d"
             cl="warning"
            :currency="idWharhouse?idWharhouse.currency:''"
            :datas="dataRevenuespay"
            :dataChart="dataRevenuespay.data"
            :sellers="sellers"
            :products="products"
            :timeserch="timeserchrevenuespay"
            :seller="seller_revenuespay"
            :product="product_revenuespay"
            :from="from_revenuespay"
            :to="to_revenuespay"
            :currentUser="currentUser"/>
      </div>
      <div v-if="currentUser && currentUser && currentUser.type && (currentUser.type=='CEO' || currentUser.type=='Seller')" class="w-full lg:w-1/2 md:w-1/2 xl:w-1/3  px-4">
        <card-v1  :statSubtitle="$t('Total Processed')"  filter="true" filterseller="true" action="Processed" 
           @ChangeDatevalue="ChangeTypeDateRevenuHeader"  @FindRecordsBetweenDate="FindRecordsHeader" @getUsersDebance="getUsersDebance"  @CheckSellerProducts="CheckSellerProducts" @removedata="removedata"
            :loading="loadingprocessed"
             color="#0d6efd"
             cl="blue"
            :currency="idWharhouse?idWharhouse.currency:''"
            :datas="dataprocessed"
            :dataChart="dataprocessed.data"
            :sellers="sellers"
            :timeserch="timeserchprocessed"
            :seller="seller_processed"
            :from="from_processed"
            :to="to_processed"
            :currentUser="currentUser"/>
      </div>
      <div :class="currentUser && currentUser.type!='CEO' && currentUser.type!='Seller'?'xl:w-1/2':'xl:w-1/3'" class="w-full lg:w-1/2 md:w-1/2  px-4">
        <card-v1  statSubtitle="Total Orders" filter="true" filterseller="true" action="Orders" 
          @ChangeDatevalue="ChangeTypeDateRevenuHeader"  @FindRecordsBetweenDate="FindRecordsHeader" @getUsersDebance="getUsersDebance" @getProducts="getProducts" @CheckSellerProducts="CheckSellerProducts" @removedata="removedata" 
            :loading="loadingorders"
             color="#bf1bb9"
             cl="marin"
            :datas="datatotalorders"
            :dataChart="datatotalorders.data"
            :sellers="sellers"
            :products="products"
            :timeserch="timeserchorders"
            :from="from_orders"
            :to="to_orders"
            :currentUser="currentUser"/>
      </div>
       <div :class="currentUser.type!='CEO' && currentUser.type!='Seller'?'xl:w-1/2':'xl:w-1/3'" class="w-full lg:w-1/2 md:w-1/2  px-4">
        <card-v1  statSubtitle="Total Products" filter="true" filterseller="true" action="Products" 
          @ChangeDatevalue="ChangeTypeDateRevenuHeader"  @FindRecordsBetweenDate="FindRecordsHeader" @getUsersDebance="getUsersDebance" @CheckSellerProducts="CheckSellerProducts" @removedata="removedata"  
            :loading="loadingproducts"
            color="#160fe1"
            cl="ceil"
            :datas="dataproducts"
            :dataChart="dataproducts.data"
            :sellers="sellers"
            :timeserch="timeserchproducts"
            :from="from_products"
            :to="to_products"
            :currentUser="currentUser"/>
      </div>
       <div v-if="currentUser && currentUser.type && currentUser.type == 'CEO'" class="w-full lg:w-1/2 md:w-1/2 xl:w-1/3  px-4">
        <card-v1  :statSubtitle="$t('Total Sellers')" action="Sellers" 
            :loading="loadingsellers"
             color="#ee8b0c"
             cl="exp"
            :timeserch="timeserchsellers"
            :datas="datasellers"
            :dataChart="datasellers.data"
            :from="from_sellers"
            :to="to_sellers"
            :currentUser="currentUser"/>
      </div>
      <div v-if="currentUser && currentUser.type && currentUser.type == 'Seller'" class="w-full lg:w-1/2 md:w-1/2 xl:w-1/3  px-4">
        <card-v1  statSubtitle="Expeditions received" filter="true" filterseller="true" action="Expeditions"   
           @getProducts="getProducts" @CheckSellerProducts="CheckSellerProducts" @removedata="removedata"  
            :timeserch="timeserchexpeditions" 
            :loading="loadingexpeditions"
             color="#ee8b0c"
             cl="exp"
            :datas="dataexpeditions"
            :dataChart="dataexpeditions.data"
            :products="products"
            :product="product_expeditions"
            :from="from_expeditions"
            :to="to_expeditions"
            :currentUser="currentUser"/>
      </div>
      <div class="w-full lg:w-full md:w-full xl:w-1/2  px-4">
        <card-v2  :statSubtitle="$t('Orders Summary')" :statSubtitleRate="$t('Callcenter/ Rate')" filter="true" filterseller="true" action="OrdersSummary"   isNew="trus" 
           @ChangeDatevalue="ChangeTypeDateRevenuHeader"  @FindRecordsBetweenDate="FindRecordsHeader" @getUsersDebance="getUsersDebance"  @getProducts="getProducts" @CheckSellerProducts="CheckSellerProducts" @removedata="removedata"  
            :timeserch="timeserchorderssummary" 
            :loading="loadingorderssummary"
             color="#ee8b0c"
            gardien="background-image: linear-gradient(115deg, rgb(32 226 215 / 31%) 0%, rgba(113, 245, 238, 0) 73%);"
             cl="exp"
            :datas="dataOrdersSummary"
            :dataChart="dataOrdersSummary.dataChart"
            :products="products"
            :sellers="sellers"
            :product="product_orderssummary"
            :seller="seller_orderssummary"
            :from="from_orderssummary"
            :to="to_orderssummary"
            :currentUser="currentUser"/>
      </div>
      <div class="w-full lg:w-full md:w-full xl:w-1/2  px-4">
        <card-v2  :statSubtitle="$t('Shippings Summary')" :statSubtitleRate="$t('Livraison/ Rate')" filter="true" filterseller="true" action="ShippingsSummary"    isNew="trus" 
           @ChangeDatevalue="ChangeTypeDateRevenuHeader"  @FindRecordsBetweenDate="FindRecordsHeader" @getUsersDebance="getUsersDebance"  @getProducts="getProducts" @CheckSellerProducts="CheckSellerProducts" @removedata="removedata"  
            :timeserch="timeserchshippingssummary" 
            :loading="loadingshippingssummary"
             color="#ee8b0c"
             gardien="background-image: linear-gradient(120deg, #a1c4fd00 0%, #c2e9fb 100%)"
             cl="exp"
            :datas="dataShippingsSummary"
            :dataChart="dataShippingsSummary.dataChart"
            :products="products"
            :sellers="sellers"
            :product="product_shippingssummary"
            :seller="seller_shippingssummary"
            :from="from_shippingssummary"
            :to="to_shippingssummary"
            :currentUser="currentUser"/>
      </div>
      <div  class="w-full  lg:w-full md:w-full  px-4" :class="currentUser.type!='Administrateur'?'xl:w-1/2':''">
        <card-v4  :statSubtitle="$t('Top Traits')" :statSubtitle1="$t('Top sellers by orders')" filter="true" filterseller="true" action="TopTraits"  isNew="trus" 
           @ChangeDatevalue="ChangeTypeDateRevenuHeader"  @FindRecordsBetweenDate="FindRecordsHeader" @getUsersDebance="getUsersDebance" @CheckSellerProducts="CheckSellerProducts" @removedata="removedata"  
            :timeserch="timeserchtoptraits" 
            :loading="loadingtoptraits"
             color="#ee8b0c"
             cl="exp"
            :dataChart="dataTopTraits"
            :sellers="sellers"
            :seller="seller_toptraits"
            :sortby_toptraits="sortby_toptraits"
            :from="from_toptraits"
            :to="to_toptraits"
            :currentUser="currentUser"/>
      </div>
       <div v-if="currentUser.type!='Administrateur'"  class="px-4 w-full  lg:w-full md:w-full xl:w-1/2">
        <card-v3  :statSubtitle="$t('Top products')" :statSubtitle1="$t('Top products by orders')" filter="true" filterseller="true" action="TopProducts"  isNew="trus"  
           @ChangeDatevalue="ChangeTypeDateRevenuHeader"  @FindRecordsBetweenDate="FindRecordsHeader" @getUsersDebance="getUsersDebance" @CheckSellerProducts="CheckSellerProducts" @removedata="removedata"  
            :timeserch="timeserchtopproducts" 
            :loading="loadingtopproducts"
             color="#ee8b0c"
             cl="exp"
            :datas="dataTopProducts"
            :dataChart="dataTopProducts.data"
            :sellers="sellers"
            :seller="seller_topproducts"
            :from="from_topproductss"
            :to="to_topproducts"
            :currentUser="currentUser"/>
      </div>
    </div>
  <!-- <div class="relative pb-4 pt-10">
     <div class="px-4 md:px-10 mx-auto w-full">
            <div class="flex-grow flex flexw-full lg:w-full md:w-full xl:w-1/2  px-4-col" v-if="currentUser.type == 'Admin' || currentUser.type == 'Seller' ||  currentUser.type == 'CEO' || $f.getAccessPermission(currentUser,'analytics','create')">
              <chart-delivery
                :dataDelivery="dataDelivery"
                :count="totalShippings"
                 color="#17a00e"
                :from="from_sh"
                :to="to_sh"
                :loading="loadingDelivrey"
                filter="true"
                :titledelivery="'Summary of orders by status'"
                typedelivery="pie"
                iddelivery="delivery-chart"
                :timeserchd="timeserchd"
                :userTypedelivery="currentUser.type"
                :timeserchsellerdelivery="timeserchsellerdelivery"
                :labelstringd="labeldeliveryString"
                :sellers="sellers"
                @ChangeTypeDateDelivery="ChangeTypeDateDelivery"
                @ChangeSellerDelivery="ChangeSellerDelivery"
                @FindRecordShippings="FindRecordShippings"
              />
          </div>  
     </div>  
    </div>   -->
    <!-- <header-stats
      v-if="currentUser.type == 'Admin' || currentUser.type == 'Seller' ||  currentUser.type == 'CEO' || $f.getAccessPermission(currentUser,'analytics','create')"
    /> -->
    <!-- <div class="flex flex-wrap p-2">
     <div
        class="w-full"
        v-if="currentUser.type == 'Admin' || currentUser.type == 'Seller' ||  currentUser.type == 'CEO'"
      >
        <chart-revenus
          :chartData="dataRevenu"
          :chartData1="dataRevenuOrders"
          :chartData2="dataRevenuOrdersPending"
          :sumrevenuorder="sumrevenuorder"
          :sumrevenusPending="sumrevenusPending"
          :sumrevenus="sumrevenus"
          :Labels="labelsrevenu"
          :currency="idWharhouse?idWharhouse.currency:''"
          :label="labelRevenu"
          :countOrders="countOrders"
          filter="true"
          :from="from_r"
          :to="to_r"
          :title="$t('revenus')"
          action="revenu"
          type="line"
          id="revenu-chart"
          :timeserch="timeserch"
           :sellers="sellers"
          :userType="currentUser.type"
          :timeserchseller="timeserchseller"
          :labelstring="labelrevenuString"
          @ChangeTypeDateRevenu="ChangeTypeDateRevenu"
          @ChangeSeller="ChangeSeller"
          @FindRecordRevenus="FindRecordRevenus"
        />
      </div>
      <div v-if="currentUser.type == 'Admin' || currentUser.type == 'CEO' || $f.getAccessPermission(currentUser,'analytics','create')" class="w-full md:w-1/2 xl:w-1/2">
        <card-bar-chart
          :chartData="dataAllSeller"
           filter="true"
          :count="countSellers"
          :title="'Total Registrants '"
          type="doughnut"
          id="seller-chart"
          :timeserch="timeserchs"
          :userType="currentUser.type"
          :labelstring="labelAllsellerString"
          @ChangeTypeDateAllSeller="ChangeTypeDateAllSeller"
          @FindRecordSellers="FindRecordSellers"
        />
      </div>
      <div
        class="w-full md:w-1/2 xl:w-1/2"
        v-if="currentUser.type == 'Admin' || currentUser.type == 'Seller' ||  currentUser.type == 'CEO' || $f.getAccessPermission(currentUser,'analytics','create')"
      >
        <chart-status-orders
          :chartData="dataconfirmation"
          :count="countConfirmations"
           filter="true"
          :title="$t('confirmation_status')"
          type="pie"
          id="confirmation-chart"
          :timeserch="timeserchc"
           :sellers="sellers"
          :userType="currentUser.type"
          :timeserchseller="timeserchsellerconfirmation"
          :labelstring="labelconfirmationString"
          @ChangeTypeDateConfirmation="ChangeTypeDateConfirmation"
          @ChangeSellerConfirmation="ChangeSellerConfirmation"
          @FindRecordconfirmations="FindRecordconfirmations"
        />
      </div>
     
      <div
        class="w-full md:w-1/2 xl:w-full"
        v-if="currentUser.type == 'Admin' || currentUser.type == 'Seller' ||  currentUser.type == 'CEO' || $f.getAccessPermission(currentUser,'analytics','create')"
      >
        <chart-revenus
          :chartData="dataRevenusellerProducts"
          :Labels="labelsrevenusellerProducts"
          :label="label"
          :title="$t('top_products')"
          type="bar"
          id="topproducts-chart"
          :userType="currentUser.type"
          :sellers="sellers"
          :timeserchseller="timeserchsellerproduct"
          :timeserch="timeserchtopproducts"
          action="topproducts"
          :labelstring="labelrevenuStringtopproduct"
          @ChangeTypeDateRevenu="ChangeTypeDateRevenu"
          @ChangeSeller="ChangeSellerOrderProduct"
        />
      </div>
     
    </div> -->

    <!-- <div
      v-if="currentUser.type == 'Admin' || currentUser.type == 'Seller' ||  currentUser.type == 'CEO' || $f.getAccessPermission(currentUser,'analytics','create')"
      class="p-6"
    >
      <chart-orders
        :dataorders="dataorders"
        :titleorders="$t('total_orders')"
        :from="from_o"
        :to="to_o"
        filter="true"
        typeorders="line"
        idorders="orders-chart"
        :timesercho="timesercho"
        :sellers="sellers"
        :userTypeorders="currentUser.type"
        :timeserchsellerorders="timeserchsellerorders"
        :labelstringo="labelordersString"
        :Labels="labels"
        @ChangeTypeDateOrders="ChangeTypeDateOrders"
        @ChangeSellerOrders="ChangeSellerOrders"
        @FindRecordOrders="FindRecordOrders"
      />
    </div> -->
  </div>
</template>

<script>
import CardBarChart from "@/components/Analytics/Cards/CardBarChart.vue";
import HeaderFilters from "@/components/Analytics/Cards/HeaderFilters.vue";
import ChartOrders from "@/components/Analytics/Cards/ChartOrders.vue";
import ChartRevenus from "@/components/Analytics/Cards/ChartRevenu.vue";
import ChartStatusOrders from "@/components/Analytics/Cards/ChartStatusOrders.vue";
import HeaderStats from "@/components/Cards/HeaderStats.vue";
import ChartDelivery from "@/components/Analytics/Cards/ChartDelivery.vue";
import CardV1 from "@/components/Analytics/Boxs/cardV1.vue";
import CardV2 from "@/components/Analytics/Boxs/cardV2.vue";
import CardV3 from "@/components/Analytics/Boxs/cardV3.vue";
import CardV4 from "@/components/Analytics/Boxs/cardV4.vue";

export default {
  name: "dashboard-page",
  components: {
    ChartRevenus,
    CardBarChart,
    HeaderFilters,
    ChartOrders,
    ChartStatusOrders,
    HeaderStats,
    ChartDelivery,
    CardV1,CardV2,CardV3,CardV4
  },
  props: {
     currentUser: { type: Object},
     idWharhouse: { type: Object},
   },
  data() {
    return {
      token: null,
      formData: {
        startDate: "",
        endDate: "",
      },
      // currentUser: {
      //   role: {},
      // },
      tab: [],
      //Revenu
      dataRevenu: [],
      dataRevenuOrders: [],
      dataRevenuOrdersPending:[],
      sumrevenuorder:0,
      sumrevenus:0,
      sumrevenusPending:0,
      countOrders:0,
      from_r:null,
      to_r:null,
      dataRevenusellerProducts: [],
      dataStock: [],
      dataExp: [],
      dataExpFees: [],
      timeserch: "thisyear",
      timeserchStock: "today",
      timeserchExp: "today",
      timeserchseller: "All",
      timeserchsellerExp: "All",
      timeserchsellerproduct: "All",
      sortby_toptraits:'',
      timeserchtopproducts: "today",
      timeserchtoptraits: "today",
      timeserchtockhistoriesproducts: "...",
      timeserchstockhistoriesSeller: "All",
      labelsMonths: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
      ],
      labels: [
        "00:00",
        "01:00",
        "02:00",
        "03:00",
        "04:00",
        "05:00",
        "06:00",
        "07:00",
        "08:00",
        "09:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
        "18:00",
        "19:00",
        "20:00",
        "21:00",
        "22:00",
        "23:00",
      ],
      labelsrevenu: [],
      labelsrevenusellerProducts: [],
      labelsStock: [],
      labelsExp: [],
      labelString: "Hours",
      labelrevenuString: "Hours",
      labelStringStock: "Hours",
      labelStringExp: "Hours",
      labelrevenuStringtopproduct: "Products",
      duree: "",
      tabsellers: [],
      tabordersbystatus: [],
      products: [],
      //**************Revenues ***************/
      timeserchrevenues: "thisyear",
      loadingrevenues:false,
      dataRevenues: {sum:0,count:0,sumFees:0,data:[0]},
      seller_revenues: null,
      product_revenues: null,
      from_revenues:null,
      to_revenues:null,
       //**************Revenues to pay***************/
      timeserchrevenuespay: "thisyear",
      loadingrevenuespay:false,
      dataRevenuespay: {sum:0,count:0,data:[0]},
      seller_revenuespay: null,
      product_revenuespay: null,
      from_revenuespay:null,
      to_revenuespay:null,
        //**************Processaid***************/
      timeserchprocessed: "thisyear",
      loadingprocessed:false,
      dataprocessed: {sum:0,count:0,data:[0]},
      seller_processed: null,
      product_processed: null,
      from_processed:null,
      to_processed:null,
         //**************Orders***************/
      timeserchorders: "thismonth",
      loadingorders:false,
      datatotalorders: {count:0,data:[0]},
      seller_orders: null,
      product_orders: null,
      from_orders:null,
      to_orders:null,
        //**************Products***************/
      timeserchproducts: "all",
      loadingproducts:false,
      dataproducts:  {count:0,data:[0]},
      seller_products: null,
      product_products: null,
      from_products:null,
      to_products:null,
        //**************Sellers***************/
      timeserchsellers: "all",
      loadingsellers:false,
      datasellers:  {count:0,data:[0],dataStatus:[0]},
      from_sellers:null,
      to_sellers:null,
       //**************Expeditions***************/
      timeserchexpeditions: "all",
      loadingexpeditions:false,
      seller_expeditions: null,
      product_expeditions: null,
      dataexpeditions:  {count:0,data:[0]},
      from_expeditions:null,
      to_expeditions:null,
     
      //************** Orders Summary ***************/
      timeserchorderssummary: "today",
      loadingorderssummary:false,
      dataOrdersSummary: {rate:0,count:0,data:[0],traits:[{ name: 'Confirmed', count: 0,color: '#17a00e',background: '#ceffca',percent:0 },{ name: 'Cancelled', count: 0 ,color: '#f41127',background: '#ffd6da',percent:0}],dataChart:[],label:[],dataOrders:[{ name: 'Pending', count: 0 },{ name: 'OutOfStock', count: 0 },{ name: 'Orange money', count: 0 },{ name: 'Unreached', count: 0},{ name: 'Double', count: 0}]},
      seller_orderssummary: null,
      product_orderssummary: null,
      from_orderssummary:null,
      to_orderssummary:null,
        //************** Shippings Summary ***************/
      timeserchshippingssummary: "today",
      loadingshippingssummary:false,
      dataShippingsSummary: {rate:0,count:0,data:[0],dataOrders:[{name:"To prepare",count:0},{name:"Prepared",count:0},{name:"Shipped",count:0},{name:"Shipped",count:0},{name:"Reprogrammer",count:0},{name:"Cancelled",count:0},{name:"Return",count:0}],traits:[{name:"Processed",percent:0,count:0,color:"#0e6efd",background:"#0e6efd57"},{name:"Paid",percent:0,count:0,color:"#ef820b",background:"#ef820b70"},{name:"Delivered",percent:0,count:0,color:"#16a00f",background:"#16a00f6b"},{name:"Return",count:0,color:"#f41127",background:"#ffd6da",percent:0}],dataChart:[],label:[]},
      seller_shippingssummary: null,
      product_shippingssummary: null,
      from_shippingssummary:null,
      to_shippingssummary:null,
       //**************Topproducts ***************/
      timeserchtopproducts: "thisyear",
      loadingtopproducts:false,
      loadingtoptraits:false,
      dataTopTraits:{series:[],sellers:[],status:[]},
      dataTopProducts: {data:[]},
      seller_topproducts: null,
      seller_toptraits:null,
      product_topproducts: null,
      from_topproductss:null,
      to_topproducts:null,
      from_toptraits:null,
      to_toptraits:null,
      //**************Dellivery ***************/
      timeserchd: "All",
      labeldeliveryString: "Days",
      dataDelivery: [],
      timeserchsellerdelivery: "All",
      totalShippings:0,
      from_sh:null,
      to_sh:null,
      /**************ConfirmationStatus ************/
      timeserchc: "today",
      labelconfirmationString: "Days",
      dataconfirmation: [],
      timeserchsellerconfirmation: "All",
      countConfirmations:0,
      from_c:null,
      to_c:null,

      /****************All Seller ***************/
      timeserchs: "today",
      labelAllsellerString: "Days",
      dataAllSeller: [],
      countSellers:0,
      from_s:null,
      to_s:null,

      /****************Total Orders *************/
      timesercho: "today",
      labelordersString: "Hours",
      dataorders: [],
      timeserchsellerorders: "All",
      from_o:null,
      to_o:null,
      /****************Label Chart************* */
      label: new Date().getFullYear(),
      labelRevenu: "rmExpress Fees",
      labelExp: "Transport fees",
      currency:"",
      //idWharhouse:null,
      sellers:[],
      citiesPrices:[],
      loadingDelivrey:false,
      sellerSelected:null,
      usesType:['NRP','Return','CancelledOrder','TeleConsultant', 'StockManager', 'Courier','Autre', 'Accountant','ToRemind'], 
      warehouses:[]
    };
  },
  computed: {
    warhouseSelected(){
        return this.$store.getters["wharhouse/warhouseSelected"]({user:_.pick(this.currentUser,['countries'])})
    },
  },
   watch: {
      'warhouseSelected': async function(oldVal, newVal) {
        //  this.$store.dispatch("actionloadingpage",{loading:true});
        //  // await this.getwharhouseId();
        //   await this.getTotalOrdersPaid({ Response: "Revenues",DateType:this.timeserchrevenues });
        //   this.$store.dispatch("actionloadingpage",{loading:false});
        //   await this.getTotalOrdersPaid({ Response: "RevenuesPay",DateType:this.timeserchrevenuespay });
        //   await this.getTotalOrdersPaid({ Response: "Processed",DateType:this.timeserchprocessed });
        //   await this.getTotalOrdersPaid({ Response: "Orders",DateType:this.timeserchorders });
        //   await this.getTotalOrdersPaid({ Response: "Products",DateType:this.timeserchproducts });
        //   if(this.currentUser && this.currentUser.type && this.currentUser.type === "Seller") await this.getTotalOrdersPaid({ Response: "Expeditions",DateType:this.timeserchexpeditions});
        //   if(this.currentUser && this.currentUser.type && this.currentUser.type != "Seller") await this.getTotalOrdersPaid({ Response: "Sellers",DateType:this.timeserchsellers});
        //   await this.getTotalOrdersPaid({ Response: "OrdersSummary",DateType:this.timeserchorderssummary });
        //   await this.getTotalOrdersPaid({ Response: "ShippingsSummary",DateType:this.timeserchshippingssummary });
        //   await this.getTotalOrdersPaid({ Response: "TopProducts",DateType:this.timeserchtopproducts });
        //   await this.getSellers();
        //   await this.CheckSellerProducts(this.currentUser,null,'seller');
        
      },
      'idWharhouse': async function(oldVal, newVal) {
            this.idWharhouse=oldVal;  
            this.$store.dispatch("actionloadingpage",{loading:true});
         // await this.getwharhouseId();
         if(this.currentUser.type=='CEO' || this.currentUser.type=='Seller'){
            await this.getTotalOrdersPaid({ Response: "Revenues",DateType:this.timeserchrevenues });
            this.$store.dispatch("actionloadingpage",{loading:false});
            await this.getTotalOrdersPaid({ Response: "RevenuesPay",DateType:this.timeserchrevenuespay });
            await this.getTotalOrdersPaid({ Response: "Processed",DateType:this.timeserchprocessed });
         }

          await this.getTotalOrdersPaid({ Response: "Orders",DateType:this.timeserchorders });
          this.$store.dispatch("actionloadingpage",{loading:false});
          await this.getTotalOrdersPaid({ Response: "Products",DateType:this.timeserchproducts });
          if(this.currentUser.type == "Seller") await this.getTotalOrdersPaid({ Response: "Expeditions",DateType:this.timeserchexpeditions});
          if(this.currentUser.type == "CEO") await this.getTotalOrdersPaid({ Response: "Sellers",DateType:this.timeserchsellers});
          await this.getTotalOrdersPaid({ Response: "OrdersSummary",DateType:this.timeserchorderssummary });
          await this.getTotalOrdersPaid({ Response: "ShippingsSummary",DateType:this.timeserchshippingssummary });
          this.sortby_toptraits='';this.timeserchtoptraits='today';this.dataTopTraits={series:[],sellers:[],status:[]};
          await this.getTotalOrdersPaid({ Response: "TopTraits",DateType:this.timeserchtoptraits,sortTopTraits:this.sortby_toptraits });
          if(this.currentUser.type!='Administrateur') await this.getTotalOrdersPaid({ Response: "TopProducts",DateType:this.timeserchtopproducts });

          
          await this.getSellers();
          await this.CheckSellerProducts(this.currentUser,null,'seller');   
        }
   },    
  async mounted() {
    this.$store.dispatch("actionloadingpage",{loading:true});
   // await this.getUser();
    this.token = this.$jwtService.verifyToken();
    console.log('this.currentUse-------',this.currentUser)
    
    //await this.getwharhouseId();
    //await this.getWarehouses();
   
   if(this.idWharhouse && this.currentUser && this.currentUser.type){
     if(!this.getPermission('dashboard') || !this.getPermission('analytics')){ 
      this.$store.dispatch("actionloadingpage",{loading:false});
      if(window.history.state) this.$router.back();
    } else{
         
        if(this.currentUser.type=='CEO' || this.currentUser.type=='Seller'){
            if(this.currentUser.type=='Seller') this.sellerSelected=this.currentUser._id;
            await this.getTotalOrdersPaid({ Response: "Revenues",DateType:this.timeserchrevenues });
            this.$store.dispatch("actionloadingpage",{loading:false});
            await this.getTotalOrdersPaid({ Response: "RevenuesPay",DateType:this.timeserchrevenuespay });
            await this.getTotalOrdersPaid({ Response: "Processed",DateType:this.timeserchprocessed });
        }
        
          await this.getTotalOrdersPaid({ Response: "Orders",DateType:this.timeserchorders });
          this.$store.dispatch("actionloadingpage",{loading:false});
          await this.getTotalOrdersPaid({ Response: "Products",DateType:this.timeserchproducts });
          if(this.currentUser.type == "Seller") await this.getTotalOrdersPaid({ Response: "Expeditions",DateType:this.timeserchexpeditions});
          if(this.currentUser.type == "CEO") await this.getTotalOrdersPaid({ Response: "Sellers",DateType:this.timeserchsellers});
          await this.getTotalOrdersPaid({ Response: "OrdersSummary",DateType:this.timeserchorderssummary });
          await this.getTotalOrdersPaid({ Response: "ShippingsSummary",DateType:this.timeserchshippingssummary });
          await this.getTotalOrdersPaid({ Response: "TopTraits",DateType:this.timeserchtoptraits,sortTopTraits:this.sortby_toptraits });
         
          if(this.currentUser.type!='Administrateur')  await this.getTotalOrdersPaid({ Response: "TopProducts",DateType:this.timeserchtopproducts });
          this.$store.dispatch('actionSidebar');
          this.$store.dispatch('actionSidebar');
         
          
          await this.getSellers();
          await this.CheckSellerProducts(this.currentUser,null,'seller');
     }
   
   }
   
   

    // await this.getTotalDelivery({
    //   DateType: this.timeserchd,
    //   Seller: this.timeserchsellerdelivery,
    //   country:await this.warhouseSelected
    // });
    // await this.getRevenus_and_TopProducts_Stock({
    //   DateType: this.timeserch,
    //   Seller: this.timeserchseller,
    //   action: "revenu",
    //   country:await this.warhouseSelected 
    // });
    // console.log('revenu sumrevenusPending__________________',this.sumrevenusPending)
    //       console.log('revenu timeserch',this.labelsrevenu)
    // await this.getRevenus_and_TopProducts_Stock({
    //   DateType: this.timeserchExp,
    //   Seller: this.timeserchsellerExp,
    //   action: "expeditions",
    // });
   


    // await this.getConfirmationStatus({
    //   DateType: this.timeserchc,
    //   Seller: this.timeserchsellerconfirmation,
    //    country:await this.warhouseSelected
    // });
    // if (this.currentUser.type == "Admin" || this.currentUser.type == 'CEO') {
    //   await this.getAllSeller({
    //     DateType: this.timeserchs,
    //   });
    // }
    // await this.getOrders({
    //   DateType: this.timesercho,
    //   Seller: this.timeserchsellerorders,
    // });

    // this.labelsrevenu=this.labels;
  },
  methods: {
     async getwharhouseId(){
          await this.$store.dispatch('wharhouse/getwharhouseId',{country:await this.warhouseSelected}).then((res) => {
                  this.idWharhouse=res;
              })
      },
      getPermission(name) {
      if (this.currentUser && this.currentUser.role) {
        const permissions = this.currentUser.role.permissions;
        for (let i in permissions) {
          let model = permissions[i]["model"];
          let access = permissions[i]["access"];
          //   console.log('Modell ',model);
          if (model == name) {
            if (
              access.create ||
              access.read ||
              access.update ||
              access.delete
            ) {
             
              if((this.currentUser.type =='CancelledOrder' || this.currentUser.type=='ToRemind') && name=='shippings') return false
              else return true;
            } else {
              //console.log("not authorized",model);
              return false;
            }
          }
        }
      }else return false;
    },
      async getWarehouses(){
        const filter = {limit:10};
        const res = await this.$server.search("warehouses",filter);
        if (res.content.results) {
          this.warehouses = res.content.results;
        } else this.warehouses = [];
      }, 
      getUsersDebance: _.debounce(async function(name) {
         console.log('name----',name)
        if(this.idWharhouse && this.idWharhouse.country){
          if (this.currentUser && this.currentUser.role && this.currentUser.role.name && this.currentUser.role.name != "Seller" && this.currentUser.type != "Seller"){
              await this.$store.dispatch('wharhouse/searchUser',{type: "Seller",warhouse:this.idWharhouse.country,search:name}).then((res) => {console.log('res seller------',res);this.sellers = res})
          }else this.sellers = [];
        }
      }, 500), 
      getProducts: _.debounce(async function(name) {
         console.log('event______________',name)
          const filter={seller: this.sellerSelected,warhouse:this.idWharhouse._id,search:name};
          console.log('filter______________',filter)
          if(this.sellerSelected) await this.$store.dispatch('wharhouse/searchProducts',filter).then((res) => {this.products = res;})
      }, 500), 
      async CheckSellerProducts(seller=null,action,model){
        console.log('seller-------',seller)
        console.log('action-------',action)
        console.log('model-------',model)
        if(model && model=='sort'){
         // if(seller){
              if(action && action =="TopTraits"){
                 this.sortby_toptraits=seller?seller.name:null;
                 let filters={Response: action,DateType:this.timeserchtoptraits,seller:this.seller_toptraits?this.seller_toptraits._id||this.seller_toptraits:null,sortTopTraits:this.sortby_toptraits};
                 if(this.timeserchtoptraits=="betweendate") filters.dates={from:this.from_toptraits,to:this.to_toptraits};
                 await this.getTotalOrdersPaid(filters);
              }
         // }
        }
       if(model && model=='seller'){
          if(seller && seller._id){
              if(action && action =="Revenues"){
                 this.seller_revenues=seller;
                 let filters={Response: action,DateType:this.timeserchrevenues,seller:this.seller_revenues?this.seller_revenues._id||this.seller_revenues:null};
                 if(this.timeserchrevenues=="betweendate") filters.dates={from:this.from_revenues,to:this.to_revenues};
                 await this.getTotalOrdersPaid(filters);
              }
              if(action && action =="TopProducts"){
                 this.seller_topproducts=seller;
                 let filters={Response: action,DateType:this.timeserchtopproducts,seller:this.seller_topproducts?this.seller_topproducts._id||this.seller_topproducts:null};
                 if(this.timeserchtopproducts=="betweendate") filters.dates={from:this.from_topproductss,to:this.to_topproducts};
                 await this.getTotalOrdersPaid(filters);
              }
              if(action && action =="TopTraits"){
                 this.seller_toptraits=seller;
                 let filters={Response: action,DateType:this.timeserchtoptraits,seller:this.seller_toptraits?this.seller_toptraits._id||this.seller_toptraits:null,sortTopTraits:this.sortby_toptraits};
                 if(this.timeserchtoptraits=="betweendate") filters.dates={from:this.from_toptraits,to:this.to_toptraits};
                 await this.getTotalOrdersPaid(filters);
              }
              if(action && action =="RevenuesPay"){
                 this.seller_revenuespay=seller;
                 let filters={Response: action,DateType:this.timeserchrevenuespay,seller:this.seller_revenuespay?this.seller_revenuespay._id||this.seller_revenuespay:null};
                 if(this.timeserchrevenuespay=="betweendate") filters.dates={from:this.from_revenuespay,to:this.to_revenuespay};
                 await this.getTotalOrdersPaid(filters);
               }
               if(action && action =="Processed"){
                 this.seller_processed=seller;
                 let filters={Response: action,DateType:this.timeserchprocessed,seller:this.seller_processed?this.seller_processed._id||this.seller_processed:null};
                 if(this.timeserchprocessed=="betweendate") filters.dates={from:this.from_processed,to:this.to_processed};
                 await this.getTotalOrdersPaid(filters);
               }
               if(action && action =="Orders"){
                 this.seller_orders=seller;
                 let filters={Response: action,DateType:this.timeserchorders,seller:this.seller_orders?this.seller_orders._id||this.seller_orders:null};
                 if(this.timeserchorders=="betweendate") filters.dates={from:this.from_orders,to:this.to_orders};
                 await this.getTotalOrdersPaid(filters);
               }
               if(action && action =="Products"){
                 this.seller_products=seller;
                 let filters={Response: action,DateType:this.timeserchproducts,seller:this.seller_products?this.seller_products._id||this.seller_products:null};
                 if(this.timeserchrevenues=="betweendate") filters.dates={from:this.from_products,to:this.to_products};
                 await this.getTotalOrdersPaid(filters);
               }
               if(action && action =="OrdersSummary"){
                 this.seller_orderssummary=seller;
                 let filters={Response: action,DateType:this.timeserchorderssummary,seller:this.seller_orderssummary?this.seller_orderssummary._id||this.seller_orderssummary:null};
                 if(this.timeserchorderssummary=="betweendate") filters.dates={from:this.from_orderssummary,to:this.to_orderssummary};
                 await this.getTotalOrdersPaid(filters);
               }
               if(action && action =="ShippingsSummary"){
                 this.seller_shippingssummary=seller;
                 let filters={Response: action,DateType:this.timeserchshippingssummary,seller:this.seller_shippingssummary?this.seller_shippingssummary._id||this.seller_shippingssummary:null};
                 if(this.timeserchshippingssummary=="betweendate") filters.dates={from:this.from_shippingssummary,to:this.to_shippingssummary};
                 await this.getTotalOrdersPaid(filters);
               }
            
              this.sellerSelected=seller._id;
              this.products = [];
              const res = await this.$server.search("products", { 'details.warehouse':this.idWharhouse._id,seller: seller._id,limit:10 });
              console.log('res product--------',res.content)
              if (res.content.results) {
                this.products = res.content.results;
              } else this.products = [];
         }else {this.sellerSelected=null;this.products = [];}
       }
        if(model && model=='product'){
              if(action && action =="Orders"){
                 this.product_orders=seller;
                 let filters={Response: action,DateType:this.timeserchorders,seller:this.seller_orders?this.seller_orders._id||this.seller_orders:null,product:this.product_orders?this.product_orders._id||this.product_orders:null};
                 if(this.timeserchrevenues=="betweendate") filters.dates={from:this.from_orders,to:this.to_orders};
                 await this.getTotalOrdersPaid(filters);
               }
              if(action && action =="RevenuesPay"){
                 this.product_revenuespay=seller;
                 let filters={Response: action,DateType:this.timeserchrevenuespay,seller:this.seller_revenuespay?this.seller_revenuespay._id||this.seller_revenuespay:null,product:this.product_revenuespay?this.product_revenuespay._id||this.product_revenuespay:null};
                 if(this.timeserchrevenues=="betweendate") filters.dates={from:this.from_revenuespay,to:this.to_revenuespay};
                 await this.getTotalOrdersPaid(filters);
               }
               if(action && action =="Expeditions"){
                 this.product_expeditions=seller;
                 let filters={Response: action,DateType:this.timeserchexpeditions,product:this.product_expeditions?this.product_expeditions._id||this.product_expeditions:null};
                 if(this.timeserchexpeditions=="betweendate") filters.dates={from:this.from_expeditions,to:this.to_expeditions};
                 await this.getTotalOrdersPaid(filters);
               }
               if(action && action =="OrdersSummary"){
                 this.product_orderssummary=seller;
                 let filters={Response: action,DateType:this.timeserchorderssummary,seller:this.seller_orderssummary?this.seller_orderssummary._id||this.seller_orderssummary:null,product:this.product_orderssummary?this.product_orderssummary._id||this.product_orderssummary:null};
                 if(this.timeserchorderssummary=="betweendate") filters.dates={from:this.from_orderssummary,to:this.to_orderssummary};
                 await this.getTotalOrdersPaid(filters);
               }
               if(action && action =="ShippingsSummary"){
                 this.product_shippingssummary=seller;
                 let filters={Response: action,DateType:this.timeserchshippingssummary,seller:this.seller_shippingssummary?this.seller_shippingssummary._id||this.seller_shippingssummary:null,product:this.product_shippingssummary?this.product_shippingssummary._id||this.product_shippingssummary:null};
                 if(this.timeserchshippingssummary=="betweendate") filters.dates={from:this.from_shippingssummary,to:this.to_shippingssummary};
                 await this.getTotalOrdersPaid(filters);
               }
            
        }
      
    },
    async removedata(action){
        if(action =="Revenues") {
           this.seller_revenues = null;
           this.product_revenues = null;
           let filters={DateType: this.timeserchrevenues,Response: action};
           if(this.timeserchrevenues=="betweendate") filters.dates={from:this.from_revenues,to:this.to_revenues};
           await this.getTotalOrdersPaid(filters);
        }
        if(action =="TopProducts") {
           this.seller_topproducts = null;
           let filters={DateType: this.timeserchtopproducts,Response: action};
           if(this.timeserchtopproducts=="betweendate") filters.dates={from:this.from_topproductss,to:this.to_topproducts};
           await this.getTotalOrdersPaid(filters);
        }
        if(action =="TopTraits") {
           this.seller_toptraits = this.sortby_toptraits = null;
           if(this.timeserchtoptraits == "betweendate" && !this.from_toptraits) this.timeserchtoptraits='today';
           let filters={DateType: this.timeserchtoptraits,Response: action};
           if(this.timeserchtoptraits=="betweendate") filters.dates={from:this.from_toptraits,to:this.to_toptraits};
           await this.getTotalOrdersPaid(filters);
        }
        if(action =="RevenuesPay") {
           this.seller_revenuespay = null;
           this.product_revenuespay = null;
           let filters={DateType: this.timeserchrevenuespay,Response: action};
           if(this.timeserchrevenuespay=="betweendate") filters.dates={from:this.from_revenuespay,to:this.to_revenuespay};
           await this.getTotalOrdersPaid(filters);
        }
        if(action =="Processed") {
           this.seller_processed = null;
           let filters={DateType: this.timeserchprocessed,Response: action};
           if(this.timeserchprocessed=="betweendate") filters.dates={from:this.from_processed,to:this.to_processed};
           await this.getTotalOrdersPaid(filters);
        }
         if(action =="Orders") {
           this.seller_orders = null;
           this.product_orders = null;
           let filters={DateType: this.timeserchorders,Response: action};
           if(this.timeserchorders=="betweendate") filters.dates={from:this.from_orders,to:this.to_orders};
           await this.getTotalOrdersPaid(filters);
        }
        if(action =="Products") {
           this.seller_products = null;
           let filters={DateType: this.timeserchproducts,Response: action};
           if(this.timeserchproducts=="betweendate") filters.dates={from:this.from_products,to:this.to_products};
           await this.getTotalOrdersPaid(filters);
        }
         if(action =="Expeditions") {
           this.seller_expeditions = null;
           this.product_expeditions = null;
           let filters={DateType: this.timeserchexpeditions,Response: action};
           if(this.timeserchorders=="betweendate") filters.dates={from:this.from_expeditions,to:this.to_expeditions};
           await this.getTotalOrdersPaid(filters);
        }
        if(action =="OrdersSummary") {
           this.seller_orderssummary = null;
           this.product_orderssummary = null;
           if(this.timeserchorderssummary == "betweendate" && !this.from_orderssummary) this.timeserchorderssummary='today';
           let filters={DateType: this.timeserchorderssummary,Response: action};
           if(this.timeserchorderssummary == "betweendate") filters.dates={from:this.from_orderssummary,to:this.to_orderssummary};
           await this.getTotalOrdersPaid(filters);
        }
        if(action =="ShippingsSummary") {
           this.seller_shippingssummary= null;
           this.product_shippingssummary = null;
           if(this.timeserchshippingssummary == "betweendate" && !this.from_shippingssummary) this.timeserchshippingssummary='today';
           let filters={DateType: this.timeserchshippingssummary,Response: action};
           if(this.timeserchshippingssummary == "betweendate" && this.from_shippingssummary) filters.dates={from:this.from_shippingssummary,to:this.to_shippingssummary};
           await this.getTotalOrdersPaid(filters);
        }
     },
      async FindRecordsHeader(action,from,to) {
         let seller=null, product=null;
        if(action =="Revenues") {
          this.from_revenues = from;
          this.to_revenues = to;
          seller=this.seller_revenues;
        }
        if(action =="TopProducts") {
          this.from_topproductss = from;
          this.to_topproducts = to;
          seller=this.seller_topproducts;
        }
        if(action =="TopTraits") {
          this.from_toptraits = from;
          this.to_toptraits = to;
          seller=this.seller_toptraits;
        }
        if(action =="RevenuesPay") {
          this.from_revenuespay = from;
          this.to_revenuespay = to;
          seller=this.seller_revenuespay;
          product=this.product_revenuespay;
        }
        if(action =="Processed") {
          this.from_processed= from;
          this.to_processed = to;
          seller=this.seller_processed;
        }
        if(action =="Orders") {
          this.from_orders= from;
          this.to_orders = to;
          seller=this.seller_orders;
          product=this.product_orders;
        }
        if(action =="Products") {
          this.from_products= from;
          this.to_products = to;
          seller=this.seller_products;
        }
        if(action =="OrdersSummary") {
          this.from_orderssummary = from;
          this.to_orderssummary = to;
          seller=this.seller_orderssummary;
          product=this.product_orderssummary;
        }
        if(action =="ShippingsSummary") {
          this.from_shippingssummary = from;
          this.to_shippingssummary = to;
          seller=this.seller_shippingssummary;
          product=this.product_shippingssummary;
        }

         await this.getTotalOrdersPaid({DateType: 'betweendate',dates:{from:from,to:to},Response: action,seller:seller?seller._id||seller:null,product:product?product._id||product:null,sortTopTraits:this.sortby_toptraits});
     },
     async ChangeTypeDateRevenuHeader(event) {
        console.log('dtata valure',event.target.value)
        console.log('dtata name',event.target.name)
        let seller=null, product=null;
      if (event.target.name == "Revenues") {
           this.timeserchrevenues = event.target.value;
           seller=this.seller_revenues;
          // product=this.product_revenues;
      }if (event.target.name == "TopProducts") {
           this.timeserchtopproducts = event.target.value;
           seller=this.seller_topproducts;
      }
      if (event.target.name == "TopTraits") {
           this.timeserchtoptraits = event.target.value;
           seller=this.seller_toptraits;
      }
      if (event.target.name == "RevenuesPay") {
           this.timeserchrevenuespay = event.target.value;
           seller=this.seller_revenuespay;
           product=this.product_revenuespay;
      }if (event.target.name == "OrdersSummary") {
           this.timeserchorderssummary = event.target.value;
           seller=this.seller_orderssummary;
           product=this.product_orderssummary;
      }if (event.target.name == "ShippingsSummary") {
           this.timeserchshippingssummary = event.target.value;
           seller=this.seller_shippingssummary;
           product=this.product_shippingssummary;
      }
      if (event.target.name == "Orders") {
           this.timeserchorders = event.target.value;
           seller=this.seller_orders;
           product=this.product_orders;
      }if (event.target.name == "Products") {
           this.timeserchproducts = event.target.value;
           seller=this.seller_products;
      }
       if(event.target.name=="Processed") {
          this.timeserchprocessed = event.target.value;
          seller=this.seller_processed;
      }if(event.target.name=="Confirmation") {
        this.timeserchconfirm = event.target.value;
         seller=this.seller_confirm;
         product=this.product_confirm;
      }if(event.target.name=="ConfirmationRate") {
        this.timeserchconfirmRate = event.target.value;
        seller=this.seller_confirmRate;
        product=this.product_confirmRate;
      }if(event.target.name=="Delivery") {
        this.timeserchdelivry = event.target.value;
        seller=this.seller_deliveryRate;
        product=this.product_deliveryRate;
      }if(event.target.name=="Accounting") {
        this.timeserchaccounting = event.target.value;
      }if(event.target.name=="callcenter") {
        // this.timeserchcallcenter = event.target.value;
        // seller=this.seller_callcenter;
        // product=this.product_callcenter;
  
        // if(this.timeserchcallcenter=='Today') this.labelCallcenter=[ "00","01","02","03","04","05","06","07","08","09","10","11","12","13","14","15","16","17","18","19","20","21","22","23",]
        // if(this.timeserchcallcenter=='This Year') this.labelCallcenter=[ "January","February","March","April","May","June","July","August","September","October","November","December",];
        // if(this.timeserchcallcenter=='This Month') this.labelCallcenter=this.getNumberOfDayinMonths(new Date());
        // if(this.timeserchcallcenter=='Last Month') { let dl = new Date();dl.setMonth(dl.getMonth() - 1);this.labelCallcenter=this.getNumberOfDayinMonths(dl);}
        //  console.log('this.labelCallcenter',this.labelCallcenter)
      }

      
      if(event.target.value != "betweendate"){
           await this.getTotalOrdersPaid({ Response: event.target.name,DateType:event.target.value ,seller:seller?seller._id||seller:null,product:product?product._id||product:null,sortTopTraits:this.sortby_toptraits});
      }
      
    },
      async getTotalOrdersPaid(filters) {
        let res;
        filters.country=this.idWharhouse.country;
        filters.warhouse=this.idWharhouse._id;
        filters.warehouses=this.warehouses;
        console.log('filters getTotalOrdersPaid-------------',filters)

        switch (filters.Response) {
          case "Revenues":
             this.loadingrevenues = true;
             res = await this.$server.getTotalOrdersPaid("analytics", filters);
             console.log('res----------res-----',res)
             this.loadingrevenues = false;
             if(res.content) this.dataRevenues = res.content;
             else this.dataRevenues={sum:0,count:0,sumFees:0,data:[0]};
            break;
          case "RevenuesPay":
             this.loadingrevenuespay = true;
             res = await this.$server.getTotalOrdersPaid("analytics", filters);
             console.log('res----------RevenuesPay-----',res)
             this.loadingrevenuespay = false;
             if(res.content) this.dataRevenuespay = res.content;
             else this.dataRevenuespay={sum:0,count:0,data:[0]};
            break;
            case "Processed":
             this.loadingprocessed = true;
             res = await this.$server.getTotalOrdersPaid("analytics", filters);
             console.log('res----------RevenuesPay-----',res)
             this.loadingprocessed = false;
             if(res.content) this.dataprocessed = res.content;
             else this.dataprocessed={sum:0,count:0,data:[0]};
            break;
            case "Orders":
             this.loadingorders = true;
             res = await this.$server.getTotalOrdersPaid("analytics", filters);
             console.log('res----------Orders-----',res)
             this.loadingorders = false;
             if(res.content) this.datatotalorders = res.content;
             else this.datatotalorders={count:0,data:[0]};
            break;
             case "Products":
             this.loadingproducts = true;
             res = await this.$server.getTotalOrdersPaid("analytics", filters);
             console.log('res----------Products-----',res)
             this.loadingproducts = false;
             if(res.content) this.dataproducts = res.content;
             else this.dataproducts={count:0,data:[0]};
            break;
            case "Expeditions":
             this.loadingexpeditions = true;
             res = await this.$server.getTotalOrdersPaid("analytics", filters);
             console.log('res----------Expeditions-----',res)
             this.loadingexpeditions = false;
             if(res.content) this.dataexpeditions = res.content;
             else this.dataexpeditions={count:0,data:[0]};
            break;
            case "Sellers":
             this.loadingsellers = true;
             res = await this.$server.getTotalOrdersPaid("analytics", filters);
             console.log('res----------Sellers-----',res)
             this.loadingsellers = false;
             if(res.content) this.datasellers = res.content;
             else this.datasellers={count:0,data:[0],dataStatus:[0]};
            break;
            case "OrdersSummary":
             this.loadingorderssummary = true;
             res = await this.$server.getTotalOrdersPaid("analytics", filters);
             console.log('res----------OrdersSummary-----',res)
             this.loadingorderssummary = false;
             if(res.content) this.dataOrdersSummary = res.content;
             else this.dataOrdersSummary={count:0,rate:0,data:[0],traits:[{ name: 'Confirmed', count: 0,color: '#17a00e',background: '#ceffca',percent:0 },{ name: 'Cancelled', count: 0 ,color: '#f41127',background: '#ffd6da',percent:0}],dataChart:[],label:[],dataOrders:[{ name: 'Pending', count: 5 },{ name: 'OutOfStock', count: 16 },{ name: 'Orange money', count: 0 },{ name: 'Unreached', count: 0},{ name: 'Double', count: 0}]};
            break;
            case "ShippingsSummary":
             this.loadingshippingssummary = true;
             res = await this.$server.getTotalOrdersPaid("analytics", filters);
             console.log('res----------ShippingsSummary-----',res)
             this.loadingshippingssummary = false;
             if(res.content) this.dataShippingsSummary = res.content;
             else this.dataShippingsSummary={count:0,rate:0,data:[0],dataorders:[{name:"To prepare",count:0},{name:"Prepared",count:0},{name:"Shipped",count:0},{name:"Reprogrammer",count:0},{name:"Refused",count:0},{name:"Cancelled",count:0},{name:"Return",count:0}],traits:[{name:"Processed",percent:0,count:0,color:"#0e6efd",background:"#0e6efd57"},{name:"Paid",percent:0,count:0,color:"#ef820b",background:"#ef820b70"},{name:"Delivered",percent:0,count:0,color:"#16a00f",background:"#16a00f6b"},{name:"Return",count:0,color:"#f41127",background:"#ffd6da",percent:0}],dataChart:[],label:[],dataOrders:[{ name: 'Pending', count: 5 },{ name: 'OutOfStock', count: 16 },{ name: 'Orange money', count: 0 },{ name: 'Unreached', count: 0}]};
            break;
            case "TopProducts":
             this.loadingtopproducts = true;
             var nom_product="";
             res = await this.$server.getTotalOrdersPaid("analytics", filters);
             console.log('res----------TopProducts-----',res)
             if(res.content && res.content.length>0){
               this.dataTopProducts.data=[];
               this.dataTopProducts.data=res.content;
                console.log('this.dataTopProducts----------',this.dataTopProducts)
             }else this.dataTopProducts={data:[]};
              this.loadingtopproducts = false;
            break;
            case "TopTraits":
             this.loadingtoptraits = true;
             var nom_product="";
             this.dataTopTraits={series:[],sellers:[],status:[]};
             res = await this.$server.getTotalOrdersPaid("analytics", filters);
             console.log('res.content toptraits----------',res)
             this.dataTopTraits=res.content;
             this.loadingtoptraits = false;
            break;
          default:
        }
    },
      async getCitiesPrices(){
            const resCities2 = await this.$server.get("zones", { allCitiesAndFees: true,country:await this.warhouseSelected});
            if(resCities2.content.length>0){
                for(let i in resCities2.content){
                    for(let j in resCities2.content[i]._id.cities){
                        this.citiesPrices.push({name:resCities2.content[i]._id.cities[j],price:resCities2.content[i]._id.price})
                    }
                }
            }else  this.citiesPrices=[];
      },
     async getCurrency(){
      await this.$store.dispatch('wharhouse/getCurrency').then((res) => {
            this.currency=res;
        })
    },
    async getSellers() {
      if(this.idWharhouse && this.currentUser && this.currentUser.role && this.currentUser.role.name && this.currentUser.role.name != "Seller" && this.currentUser.type != "Seller") {
          const res = await this.$server.search("users", {type: "Seller",countries:this.idWharhouse.country,limit:10,});
          console.log('ressellers',res)
          if (res.content.results) this.sellers = res.content.results;
          else this.sellers = [];
       }else this.sellers = [];
    },
     async FindRecordRevenus(from,to) {
        this.labelsrevenu = [];
        this.dataRevenu = [];
        this.dataRevenuOrders = [];this.dataRevenuOrdersPending=[];
        this.countOrders=0;
        this.sumrevenuorder=this.sumrevenus=0;this.sumrevenusPending=0;
       this.from_r = from;
       this.to_r = to;

      await this.getRevenus_and_TopProducts_Stock({
        DateType: 'betweendate',
        dates:{from:from,to:to},
        action: 'revenu',
        country:await this.warhouseSelected 
      });
     },
    async FindRecordShippings(from,to) {
       this.from_sh = from;
       this.to_sh = to;

      await this.getTotalDelivery({
        DateType:  'betweendate',
        dates:{from:from,to:to},
        country:await this.warhouseSelected
      });
     },
      async FindRecordOrders(from,to) {
       this.from_o = from;
       this.to_o = to;
       this.labelordersString = "Days";
       await this.getOrders({
        DateType:'betweendate',
        dates:{from:from,to:to},
        Seller: this.timeserchsellerorders,
      });
     },
     async FindRecordSellers(from,to) {
       this.from_s = from;
       this.to_s= to;

      await this.getAllSeller({
        DateType: 'betweendate',
        dates:{from:from,to:to},
      });
     },
     async FindRecordconfirmations(from,to) {
       this.from_c = from;
       this.to_c= to;

      await this.getConfirmationStatus({
        DateType: 'betweendate',
        dates:{from:from,to:to},
         country:await this.warhouseSelected
      });
     },
    async getProductById(id) {
      const result = await this.$server.get("products", { _id: id });
      let lenghtString=20;
      if(this.currentUser.type == "Seller") lenghtString=30;
       //console.log('product_____', result.content)
      if (result.content) {
        var nom_product = result.content.name;
        if (nom_product && nom_product.length > 10) nom_product = nom_product.substring(0, lenghtString) + "...";
        return {name:nom_product,seller:result.content.seller?result.content.seller.fullName:this.currentUser.fullName};
      }
    },
    GetMonthName(monthNumber) {
      var months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      return months[monthNumber - 1];
    },
    getTymeType() {
      var type = "";
      if (this.timeserch == "today") {
        type = "hour";
      } else if (this.timeserch == "thisyear") {
        type = "month";
      } else {
        type = "day";
      }
      return type;
    },
    getTymeTypeStock() {
      var type = "";
      if (this.timeserchStock == "today") {
        type = "hour";
      } else if (this.timeserchStock == "thisyear") {
        type = "month";
      } else {
        type = "day";
      }
      return type;
    },
    getTymeTypeExp() {
      var type = "";
      if (this.timeserchExp == "today") {
        type = "hour";
      } else if (this.timeserchExp == "thisyear") {
        type = "month";
      } else {
        type = "day";
      }
      return type;
    },
    async ChangeTypeDateRevenu(event) {
      if (event.target.name == "revenu") {
        this.timeserch = event.target.value;
        console.log('event.target.value',event.target.value)
         if( event.target.value != "betweendate"){
            this.labelsrevenu = [];
            this.dataRevenu = [];
            this.dataRevenuOrders = [];this.dataRevenuOrdersPending=[];
            this.countOrders=0;
            this.sumrevenuorder=this.sumrevenus=0;this.sumrevenusPending=0;
            if (event.target.value == "today") {
              this.labelrevenuString = "Hours";
            } else if (event.target.value == "thisyear") {
              this.labelrevenuString = "Months";
            } else {
              this.labelrevenuString = "Days";
            }
         }else{
             this.labelrevenuString = "Days";
         }
      } else if (event.target.name == "stockhistories") {
        this.timeserchStock = event.target.value;
        this.labelsStock = [];
        this.dataStock = [];
        if (event.target.value == "today") {
          this.labelStringStock = "Hours";
        } else if (event.target.value == "thisyear") {
          this.labelStringStock = "Months";
        } else {
          this.labelStringStock = "Days";
        }
      } else if (event.target.name == "expeditions") {
        this.timeserchExp = event.target.value;
        this.labelsExp = [];
        this.dataExp = [];
        this.dataExpFees = [];
        if (event.target.value == "today") {
          this.labelStringExp = "Hours";
        } else if (event.target.value == "thisyear") {
          this.labelStringExp = "Months";
        } else {
          this.labelStringExp = "Days";
        }
      } else if (event.target.name == "expeditions") {
        this.timeserchtoptraits = event.target.value;
      } else {
        this.timeserchtopproducts = event.target.value;
        this.dataRevenusellerProducts = [];
        this.labelsrevenusellerProducts = [];
      }
      console.log('target',event.target.name)
      if( event.target.value != "betweendate"){
        await this.getRevenus_and_TopProducts_Stock({
          DateType: event.target.value,
          Product: this.timeserchtockhistoriesproducts,
          action: event.target.name,
           country:await this.warhouseSelected 
        });
      }
    },
    async ChangeSeller(seller) {
      this.timeserchseller = seller;
      this.dataRevenu = [];
      this.dataRevenuOrders = [];this.dataRevenuOrdersPending=[];
      this.sumrevenuorder=this.sumrevenus=0;this.sumrevenusPending=0;
      this.countOrders=0;
      this.labelsrevenu = [];
      await this.getRevenus_and_TopProducts_Stock({
        DateType: this.timeserch,
        Seller: this.timeserchseller,
        action: "revenu",
         country:await this.warhouseSelected 
      });
    },
    async ChangeSellerExp(seller) {
      this.timeserchsellerExp = seller._id;
      this.dataExp = [];
      this.dataExpFees = [];
      this.labelsExp = [];
      await this.getRevenus_and_TopProducts_Stock({
        DateType: this.timeserchExp,
        Seller: this.timeserchsellerExp,
        action: "expeditions",
      });
    },
    async ChangeProduct(product) {
      this.timeserchtockhistoriesproducts = product._id;
      this.dataStock = [];
      this.labelsStock = [];
      await this.getRevenus_and_TopProducts_Stock({
        DateType: this.timeserchStock,
        Product: this.timeserchtockhistoriesproducts,
        action: "stockhistories",
      });
    },
    async ChangeSellerOrderProduct(seller) {
      this.timeserchsellerproduct = seller._id;
      this.dataRevenusellerProducts = [];
      this.labelsrevenusellerProducts = [];
      await this.getRevenus_and_TopProducts_Stock({
        DateType: this.timeserchtopproducts,
        Sellerproduct: this.timeserchsellerproduct,
         country:await this.warhouseSelected,
        action: "topproducts",
      });
    },
    async getUser() {
      const res = await this.$server.me("users");

      if (res.content) this.currentUser = res.content;
      else this.currentUser = {};
    },
    // async getOrders(filters) {
    //   const res = await this.$server.getOrders("analytics", filters);
    //   this.tab = res.content;
    // },
    async getRevenus_and_TopProducts_Stock(filters) {
      if (filters.action == "revenu") {filters.Seller = this.timeserchseller;}
      else if (filters.action == "stockhistories")
        filters.SellerproductStock = this.timeserchstockhistoriesSeller;
      else filters.Sellerproduct = this.timeserchsellerproduct;
      filters.warhouse=this.idWharhouse._id;
      const res = await this.$server.getRevenus_and_TopProducts_Stock(
        "analytics",
        filters
      );
      console.log('getRevenus_and_TopProducts_Stock',res)
      var nom_product = "";
      if (res.content) {
        if (filters.action == "revenu"){
          // res.content.sort(
          //   (a, b) => a._id[this.getTymeType()] - b._id[this.getTymeType()]
          // );
         } else if (filters.action == "expeditions")
          res.content.sort(
            (a, b) =>
              a._id[this.getTymeTypeExp()] - b._id[this.getTymeTypeExp()]
          );
        else{
          res.content.sort(
            (a, b) =>
              a._id[this.getTymeTypeStock()] - b._id[this.getTymeTypeStock()]
          );
        }
         if (filters.action == "revenu") {  
           console.log('revenu__________________',res.content)
            this.dataRevenu=res.content.total_revenus;
            this.dataRevenuOrders=res.content.total_revenus_orders;
            this.dataRevenuOrdersPending=res.content.dataPendingToPaid;
            this.countOrders=res.content.countOrders;
            if (this.timeserch == "today") {
              this.labelsrevenu=this.labels;
            } else if (this.timeserch == "thisyear") {
              this.labelsrevenu=this.labelsMonths;
            }else if (this.timeserch == "thisweek" || this.timeserch == "lastweek") {
              this.labelsrevenu=res.content.label;
            }else if (this.timeserch == "betweendate") {
              this.labelsrevenu=res.content.label;
              if(res.content.countMonths>1){
                 for(let i in this.labelsrevenu){
                    this.labelsrevenu[i]=this.GetMonthName(this.labelsrevenu[i]);
                 }
              }
            }else {
              let nbrjours = this.getNumberOfMonths();
              for (let i = 1; i <= nbrjours; i++) {
                this.labelsrevenu.push(i);
              }
            }

        for(let j in this.dataRevenuOrders){
          this.sumrevenuorder+=this.dataRevenuOrders[j];
        }
         for(let l in this.dataRevenuOrdersPending){
          this.sumrevenusPending+=this.dataRevenuOrdersPending[l];
        }
        for(let j in this.dataRevenu){
          this.sumrevenus+=this.dataRevenu[j];
        }
          
         }
        if (filters.action != "revenu") {   
          for (const element of res.content) {
          if (filters.action == "stockhistories") {
              this.dataStock.push(element.quantityInStock);
              if (this.timeserchStock == "today") {
                this.labelsStock.push(("0" + element._id.hour).slice(-2));
              } else if (this.timeserchStock == "thisyear") {
                this.labelsStock.push(this.GetMonthName(element._id.month));
              } else {
                this.labelsStock.push(("0" + element._id.day).slice(-2));
              }
            } if (filters.action == "expeditions") {
              this.dataExpFees.push(element.transport_fees);
              this.dataExp.push(element.count);
              if (this.timeserch == "today") {
                this.labelsExp.push(("0" + element._id.hour).slice(-2));
              } else if (this.timeserch == "thisyear") {
                this.labelsExp.push(this.GetMonthName(element._id.month));
              } else {
                this.labelsExp.push(("0" + element._id.day).slice(-2));
              }
            } if(filters.action=="topproducts") {
              //top product
              this.dataRevenusellerProducts.push(element.totalSales);
              nom_product = await this.getProductById(element._id.productId);
              this.labelsrevenusellerProducts.push(nom_product);
            }
          }
        }
        
        
      } else {
        if (filters.action == "revenu") {
          this.dataRevenu = [];
          this.dataRevenuOrders = [];this.dataRevenuOrdersPending=[];
          this.sumrevenuorder=this.sumrevenus=0;this.sumrevenusPending=0;
           this.countOrders=0;
          this.labelsrevenu = [];
        }
        if (filters.action == "expeditions") {
          this.dataExp = [];
          this.dataExpFees = [];
          this.labelsExp = [];
        }
      }
    },

    async getNumberOfOrders(filters) {
      const res = await this.$server.getNumberOfOrders("analytics", filters);
      this.tabordersbystatus = res.content;
    },
    getNumberOfMonths() {
      let today = new Date();
      let month = today.getMonth() + 1;
      let year = today.getFullYear();

      let nbr_jours = new Date(year, month, 0).getDate();
      this.labels = [];
      return nbr_jours;
    },

    getNumberOfDaysLastMonths() {
      let today = new Date();
      let month = today.getMonth() + 1;
      let year = today.getFullYear();
      let nbr_jours;
      if (month == 1) {
        nbr_jours = new Date(year - 1, 12, 0).getDate();
      } else {
        nbr_jours = new Date(year, month - 1, 0).getDate();
      }
      this.labels = [];
      return nbr_jours;
    },

    async getOrdersBetweenTwoDate() {
      const data = {
        startDate: this.formData.startDate,
        endDate: this.formData.endDate,
      };
      if (
        this.formData.startDate != undefined &&
        this.formData.endDate != undefined
      ) {
        const res = await this.$server.getOrdersBetweenTwoDate(
          "analytics",
          data
        );
        this.duree = "";
        this.duree = "Two Date";
        return res.content;
      }
    },

    /****************************************************Delivery ***********************************/
    async ChangeTypeDateDelivery(event) {
      this.timeserchd = event.target.value;

    if( event.target.value != "betweendate"){
      this.labelsdelivery = [];
      this.dataDelivery = [];
      if (event.target.value == "today") {
        this.labeldeliveryString = "Hours";
      } else if (event.target.value == "thisyear") {
        this.labeldeliveryString = "Months";
      } else {
        this.labeldeliveryString = "Days";
      }
          await this.getTotalDelivery({
             DateType: this.timeserchd,
            Seller: this.timeserchsellerdelivery,
            country:await this.warhouseSelected
          });
    
       }else{
          this.labeldeliveryString = "Days";
       }

    },

    async getTotalDelivery(filters) {
      filters.Seller = this.timeserchsellerdelivery;
      this.loadingDelivrey=true;
      const res = await this.$server.getTotalDelivery("analytics", filters);
      this.loadingDelivrey=false;
      console.log("hiii je suis res delevry", res);
      if (res.content.status) {
         res.content.status.forEach(item => {
          if(item.status=="to prepare") {item.color="#afa055"}
          if(item.status=="shipped") {item.color='#bf1ab9';}
          if(item.status=="delivered") {item.color='#48bb78';}
          if(item.status=="paid") {item.color='#9329a8';}
           if(item.status=="prepared") {item.color='#4299e1';}
            if(item.status=="return") {item.color='#b97c09';}
          if(item.status=="cancelled") {item.color='red';}
        });
         this.dataDelivery = res.content.status;
        this.totalShippings= res.content.countAll;
      }
    },
    async ChangeSellerDelivery(seller) {
      if(seller){
        this.timeserchsellerdelivery = seller._id;
        this.datadelivery = [];
        await this.getTotalDelivery({
          DateType: this.timeserchd,
          Seller: this.timeserchsellerdelivery,
          country:await this.warhouseSelected
        });
      }
      
    },

    /**********************************************Fin Delivery ***********************************/

    /***********************************************Debut Confirmation Status *********************/

    async ChangeTypeDateConfirmation(event) {
      this.timeserchc = event.target.value;
    if( event.target.value != "betweendate"){
          this.dataconfirmation = [];
          if (event.target.value == "today") {
            this.labelconfirmationString = "Hours";
          } else if (event.target.value == "thisyear") {
            this.labelconfirmationString = "Months";
          } else {
            this.labelconfirmationString = "Days";
          }

          await this.getConfirmationStatus({
            DateType: event.target.value,
             country:await this.warhouseSelected
          });
     }
    },

    async getConfirmationStatus(filters) {
      filters.Seller = this.timeserchsellerconfirmation;
      const res = await this.$server.getConfirmationStatus(
        "analytics",
        filters
      );

      if (res.content) {
        this.dataconfirmation = res.content.status;
        this.countConfirmations = res.content.countAll;
      }
    },
    async ChangeSellerConfirmation(seller) {
      this.timeserchsellerconfirmation = seller._id;

      console.log(
        "HIII je suis  this.timeserchsellerconfirmation ",
        this.timeserchsellerconfirmation
      );
      this.dataconfirmation = [];

      await this.getConfirmationStatus({
        DateType: this.timeserchc,
        Seller: this.timeserchsellerconfirmation,
        country:await this.warhouseSelected
      });
    },

    /**********************************************Fin Confirmation Status ***********************************/
    /***********************************************Total Seller  **********************************************/
    async ChangeTypeDateAllSeller(event) {
      this.timeserchs = event.target.value;
      if( event.target.value != "betweendate"){
          this.dataAllSeller = [];
          if (event.target.value == "today") {
            this.labelAllsellerString = "Hours";
          } else if (event.target.value == "thisyear") {
            this.labelAllsellerString = "Months";
          } else {
            this.labelAllsellerString = "Days";
          }

          await this.getAllSeller({
            DateType: event.target.value,
          });
      }
    },

    async getAllSeller(filters) {
       filters['country']=await this.warhouseSelected;
      const res = await this.$server.getAllSeller("analytics", filters);
      if (res.content) {
          this.countSellers=0;
          this.dataAllSeller = res.content.status;
          for(let i in this.dataAllSeller){
            this.countSellers+= this.dataAllSeller[i];
          }
      }
    },

    /***********************************************Fin total Seller **********************************/

    /**********************************************Total Orders ***************************************/

    async ChangeTypeDateOrders(event) {
      this.timesercho = event.target.value;
      if( event.target.value != "betweendate"){
      this.dataorders = [];
      this.labels = [];
      if (event.target.value == "today") {
        this.labelordersString = "Hours";
        if (this.timesercho == "today") {
          this.labels = [
            "00:00",
            "01:00",
            "02:00",
            "03:00",
            "04:00",
            "05:00",
            "06:00",
            "07:00",
            "08:00",
            "09:00",
            "10:00",
            "11:00",
            "12:00",
            "13:00",
            "14:00",
            "15:00",
            "16:00",
            "17:00",
            "18:00",
            "19:00",
            "20:00",
            "21:00",
            "22:00",
            "23:00",
          ];
        }
      } else if (event.target.value == "thismonth") {
        this.labelordersString = "Days";
        if (this.timesercho == "thismonth") {
          let nbrjours = this.getNumberOfMonths();
          for (let i = 1; i <= nbrjours; i++) {
            this.labels.push(i);
          }
        }
      } else {
        this.labelordersString = "Months";
        this.labels = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];
      }
    
      await this.getOrders({
        DateType: event.target.value,
        Seller: this.timeserchsellerorders,
      });
      }
    },

    async getOrders(filters) {
      filters.Seller = this.timeserchsellerorders;
      filters.country = await this.warhouseSelected;
      const res = await this.$server.getOrders("analytics", filters);

      if (res.content) {
        if(filters.DateType=='betweendate') {
          this.dataorders = res.content.total_orders;
          this.labels=res.content.label
          if(res.content.countMonths>1) {
             for(let i in this.labels){
                this.labels[i]=this.GetMonthName(this.labels[i]);
             }
          }
          else this.labels=res.content.label
        }
        else this.dataorders = res.content;
        console.log('dataorders',this.dataorders)
      }
    },
    async ChangeSellerOrders(seller) {
      this.timeserchsellerorders = seller._id;
      this.dataorders = [];

      await this.getOrders({
        DateType: this.timesercho,
        Seller: this.timeserchsellerorders,
      });
    },
    /***********************************************Fin total Orders **********************************/
  },
};
</script>


<style >
.bestseller:before {
  content: "";
  z-index: -1;
  overflow: hidden;
  transform: rotate(-135deg);
  width: 120px;
  display: block; 
  background: #d3362d;
  background: linear-gradient(#bb2f28 0%, #e57368 100%);
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 34px;
  right: -16px;
  clip-path: polygon(120px 20px, 90px -10px, 30px -10px, 0px 20px, 10px 30px,  110px 30px);
  height: 20px;
  width: 120px;
}
.bestseller:after {
  content: "New";
  z-index: 1;
  overflow: hidden;
  font-size: 10px;
  font-weight: bold;
  color: #FFF;
  text-transform: uppercase;
  text-align: center;
  line-height: 20px;
  transform: rotate(45deg);
  width: 120px;
  display: block; 
  background: #d3362d;
  background: linear-gradient(#bb2f28 0%, #e57368 100%);
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 20px; right: -30px;
  clip-path: polygon(120px 20px, 90px -10px, 30px -10px, 0px 20px, 10px 30px,  110px 30px)
}
.vs__dropdown-toggle{
  border: none;
}
.highcharts-credits{
  display: none;
}

.icon::after {
  content: "";
  display: block;
  position: absolute;
  border-top: 23px solid transparent;
  border-bottom: 17px solid transparent;
  border-left: 12px solid #3182ce;
  left: 100%;
  top: 0;
}
.h-350-px {
  height: 350px;
}
.bg-transparent {
  background-color: transparent !important;
}
.card-header {
  padding: 1.25rem 1.5rem;
  margin-bottom: 0;
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.align-items-center {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}
.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.text-light {
  color: #ced4da !important;
}
.ls-1 {
  letter-spacing: 0.0625rem;
}
.justify-content-end {
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}
.nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.nav-pills .nav-item:not(:last-child) {
  padding-right: 1rem;
}
.nav-pills .nav-link {
  padding: 0.75rem 1rem;
  color: #5e72e4;
  font-weight: 500;
  font-size: 0.875rem;
  -webkit-box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11),
    0 1px 3px rgba(0, 0, 0, 0.08);
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  background-color: #fff;
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}
.nav-pills .nav-link {
  border-radius: 0.375rem;
}
.d-md-block {
  display: block !important;
}
.card-body {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.5rem;
}
.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
  position: relative;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  height: 100px;
}
.btn-disable{
    cursor: not-allowed;
    pointer-events: none;
}
.highcharts-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  text-align: left;
  line-height: normal;
  z-index: 0;
  /* #1072 */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-family: "Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif;
  font-size: 12px;
}

.highcharts-root {
  display: block;
}

.highcharts-root text {
  stroke-width: 0;
}

.highcharts-strong {
  font-weight: bold;
}

.highcharts-emphasized {
  font-style: italic;
}

.highcharts-anchor {
  cursor: pointer;
}

.highcharts-background {
  fill: #ffffff;
}

.highcharts-plot-border, .highcharts-plot-background {
  fill: none;
}

.highcharts-label-box {
  fill: none;
}

.highcharts-button-box {
  fill: inherit;
}

.highcharts-tracker-line {
  stroke-linejoin: round;
  stroke: rgba(192, 192, 192, 0.0001);
  stroke-width: 22;
  fill: none;
}

.highcharts-tracker-area {
  fill: rgba(192, 192, 192, 0.0001);
  stroke-width: 0;
}

/* Titles */
.highcharts-title {
  fill: #333333;
  font-size: 1.5em;
}

.highcharts-subtitle {
  fill: #666666;
}

/* Axes */
.highcharts-axis-line {
  fill: none;
  stroke: #ccd6eb;
}

.toptraits .highcharts-color-5,.toptraits.dates .highcharts-color-4 {
    fill: #08a60d;
    stroke: #08a60dc9;
}
.toptraits .highcharts-color-6 {
    fill: #78ee8a;
    stroke: #95eea2;
}
.toptraits .highcharts-color-4,.toptraits.dates .highcharts-color-3 {
    fill: #f727ea;
    stroke: #f354e8;
}
.toptraits .highcharts-color-0 {
    fill: #0e0f0f;
    stroke: #282929;
}
.toptraits .highcharts-color-1 {
    fill: rgb(222, 119, 8);
    stroke: rgb(239, 168, 93);
}
.toptraits  .highcharts-color-3 ,.toptraits.dates .highcharts-color-2{
    fill: #de0f0f;
    stroke: #e85b5b;
}

.toptraits  .highcharts-color-2,.toptraits.dates .highcharts-color-1 {
    fill: #0e68b7;
    stroke: #65acea;
}

.highcharts-yaxis .highcharts-axis-line {
  stroke-width: 0;
}

.highcharts-axis-title {
  fill: #666666;
}

.highcharts-axis-labels {
  fill: #666666;
  cursor: default;
  font-size: 0.9em;
}

.highcharts-grid-line {
  fill: none;
  stroke: #e6e6e6;
}

.highcharts-xaxis-grid .highcharts-grid-line {
  stroke-width: 0px;
}

.highcharts-tick {
  stroke: #ccd6eb;
}

.highcharts-yaxis .highcharts-tick {
  stroke-width: 0;
}

.highcharts-minor-grid-line {
  stroke: #f2f2f2;
}

.highcharts-crosshair-thin {
  stroke-width: 1px;
  stroke: #cccccc;
}

.highcharts-crosshair-category {
  stroke: #ccd6eb;
  stroke-opacity: 0.25;
}

/* Credits */
.highcharts-credits {
  cursor: pointer;
  fill: #999999;
  font-size: 0.7em;
  transition: fill 250ms, font-size 250ms;
}

.highcharts-credits:hover {
  fill: black;
  font-size: 1em;
}

/* Tooltip */
.highcharts-tooltip {
  cursor: default;
  pointer-events: none;
  white-space: nowrap;
  transition: stroke 150ms;
}

.highcharts-tooltip text {
  fill: #333333;
}

.highcharts-tooltip .highcharts-header {
  font-size: 0.85em;
}

.highcharts-tooltip-box {
  stroke-width: 1px;
  fill: #f7f7f7;
  fill-opacity: 0.85;
}

.highcharts-tooltip-box .highcharts-label-box {
  fill: #f7f7f7;
  fill-opacity: 0.85;
}

div.highcharts-tooltip {
  filter: none;
}

.highcharts-selection-marker {
  fill: #335cad;
  fill-opacity: 0.25;
}

.highcharts-graph {
  fill: none;
  stroke-width: 2px;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.highcharts-state-hover .highcharts-graph {
  stroke-width: 3;
}

.highcharts-point-inactive {
  opacity: 0.2;
  transition: opacity 50ms;
  /* quick in */
}

.highcharts-series-inactive {
  opacity: 0.2;
  transition: opacity 50ms;
  /* quick in */
}

.highcharts-state-hover path {
  transition: stroke-width 50ms;
  /* quick in */
}

.highcharts-state-normal path {
  transition: stroke-width 250ms;
  /* slow out */
}

/* Legend hover affects points and series */
g.highcharts-series,
.highcharts-point,
.highcharts-markers,
.highcharts-data-labels {
  transition: opacity 250ms;
}

.highcharts-legend-series-active g.highcharts-series:not(.highcharts-series-hover),
.highcharts-legend-point-active .highcharts-point:not(.highcharts-point-hover),
.highcharts-legend-series-active .highcharts-markers:not(.highcharts-series-hover),
.highcharts-legend-series-active .highcharts-data-labels:not(.highcharts-series-hover) {
  opacity: 0.2;
}

/* Series options */
/* Default colors */
.highcharts-color-0 {
  fill: #0d6efd;
  stroke: #0d6efd;
}

.highcharts-color-1 {
  fill: #f41127;
  stroke: #f41127;
}

.highcharts-color-2 {
  fill: #ffc107;
  stroke: #ffc107;
}

.highcharts-color-3 {
  fill: #17a00e;
  stroke: #17a00e;
}

.highcharts-color-4 {
  fill: #212529;
  stroke: #212529;
}

.highcharts-color-5 {
  fill: #0dcaf0;
  stroke: #0dcaf0;
}

.highcharts-color-6 {
  fill: #607d8b;
  stroke: #607d8b;
}

.highcharts-color-7 {
  fill: #ee14e0;
  stroke: #ee14e0;
}

.highcharts-color-8 {
  fill: #01dec9;
  stroke: #01dec9;
}

.highcharts-color-9 {
  fill: rgba(255, 255, 255, 0.07);
  stroke: rgba(255, 255, 255, 0.07);
}

.highcharts-area {
  fill-opacity: 0.75;
  stroke-width: 0;
}

.highcharts-markers {
  stroke-width: 1px;
  stroke: #ffffff;
}

.highcharts-point {
  stroke-width: 1px;
}

.highcharts-dense-data .highcharts-point {
  stroke-width: 0;
}

.highcharts-data-label {
  font-size: 0.9em;
  font-weight: bold;
}

.highcharts-data-label-box {
  fill: none;
  stroke-width: 0;
}

.highcharts-data-label text, text.highcharts-data-label {
  fill: #333333;
}

.highcharts-data-label-connector {
  fill: none;
}

.highcharts-data-label-hidden {
  pointer-events: none;
}

.highcharts-halo {
  fill-opacity: 0.25;
  stroke-width: 0;
}

.highcharts-series:not(.highcharts-pie-series) .highcharts-point-select,
.highcharts-markers .highcharts-point-select {
  fill: #cccccc;
  stroke: #000000;
}

.highcharts-column-series rect.highcharts-point {
  stroke: #ffffff;
}

.highcharts-column-series .highcharts-point {
  transition: fill-opacity 250ms;
}

.highcharts-column-series .highcharts-point-hover {
  fill-opacity: 0.75;
  transition: fill-opacity 50ms;
}

.highcharts-pie-series .highcharts-point {
  stroke-linejoin: round;
  stroke: #ffffff;
}

.highcharts-pie-series .highcharts-point-hover {
  fill-opacity: 0.75;
  transition: fill-opacity 50ms;
}

.highcharts-funnel-series .highcharts-point {
  stroke-linejoin: round;
  stroke: #ffffff;
}

.highcharts-funnel-series .highcharts-point-hover {
  fill-opacity: 0.75;
  transition: fill-opacity 50ms;
}

.highcharts-funnel-series .highcharts-point-select {
  fill: inherit;
  stroke: inherit;
}

.highcharts-pyramid-series .highcharts-point {
  stroke-linejoin: round;
  stroke: #ffffff;
}

.highcharts-pyramid-series .highcharts-point-hover {
  fill-opacity: 0.75;
  transition: fill-opacity 50ms;
}

.highcharts-pyramid-series .highcharts-point-select {
  fill: inherit;
  stroke: inherit;
}

.highcharts-solidgauge-series .highcharts-point {
  stroke-width: 0;
}

.highcharts-treemap-series .highcharts-point {
  stroke-width: 1px;
  stroke: #e6e6e6;
  transition: stroke 250ms, fill 250ms, fill-opacity 250ms;
}

.highcharts-treemap-series .highcharts-point-hover {
  stroke: #999999;
  transition: stroke 25ms, fill 25ms, fill-opacity 25ms;
}

.highcharts-treemap-series .highcharts-above-level {
  display: none;
}

.highcharts-treemap-series .highcharts-internal-node {
  fill: none;
}

.highcharts-treemap-series .highcharts-internal-node-interactive {
  fill-opacity: 0.15;
  cursor: pointer;
}

.highcharts-treemap-series .highcharts-internal-node-interactive:hover {
  fill-opacity: 0.75;
}

.highcharts-vector-series .highcharts-point {
  fill: none;
  stroke-width: 2px;
}

.highcharts-windbarb-series .highcharts-point {
  fill: none;
  stroke-width: 2px;
}

.highcharts-lollipop-stem {
  stroke: #000000;
}

.highcharts-focus-border {
  fill: none;
  stroke-width: 2px;
}

.highcharts-legend-item-hidden .highcharts-focus-border {
  fill: none !important;
}

/* Legend */
.highcharts-legend-box {
  fill: none;
  stroke-width: 0;
}

.highcharts-legend-item > text {
  fill: #333333;
  font-weight: bold;
  font-size: 1em;
  cursor: pointer;
  stroke-width: 0;
}

.highcharts-legend-item:hover text {
  fill: #000000;
}

.highcharts-legend-item-hidden * {
  fill: #cccccc !important;
  stroke: #cccccc !important;
  transition: fill 250ms;
}

.highcharts-legend-nav-active {
  fill: #003399;
  cursor: pointer;
}

.highcharts-legend-nav-inactive {
  fill: #cccccc;
}

circle.highcharts-legend-nav-active, circle.highcharts-legend-nav-inactive {
  /* tracker */
  fill: rgba(192, 192, 192, 0.0001);
}

.highcharts-legend-title-box {
  fill: none;
  stroke-width: 0;
}

/* Bubble legend */
.highcharts-bubble-legend-symbol {
  stroke-width: 2;
  fill-opacity: 0.5;
}

.highcharts-bubble-legend-connectors {
  stroke-width: 1;
}

.highcharts-bubble-legend-labels {
  fill: #333333;
}

/* Loading */
.highcharts-loading {
  position: absolute;
  background-color: #ffffff;
  opacity: 0.5;
  text-align: center;
  z-index: 10;
  transition: opacity 250ms;
}

.highcharts-loading-hidden {
  height: 0 !important;
  opacity: 0;
  overflow: hidden;
  transition: opacity 250ms, height 250ms step-end;
}

.highcharts-loading-inner {
  font-weight: bold;
  position: relative;
  top: 45%;
}

/* Plot bands and polar pane backgrounds */
.highcharts-plot-band, .highcharts-pane {
  fill: #000000;
  fill-opacity: 0.05;
}

.highcharts-plot-line {
  fill: none;
  stroke: #999999;
  stroke-width: 1px;
}

/* Highcharts More and modules */
.highcharts-boxplot-box {
  fill: #ffffff;
}

.highcharts-boxplot-median {
  stroke-width: 2px;
}

.highcharts-bubble-series .highcharts-point {
  fill-opacity: 0.5;
}

.highcharts-errorbar-series .highcharts-point {
  stroke: #000000;
}

.highcharts-gauge-series .highcharts-data-label-box {
  stroke: #cccccc;
  stroke-width: 1px;
}

.highcharts-gauge-series .highcharts-dial {
  fill: #000000;
  stroke-width: 0;
}

.highcharts-polygon-series .highcharts-graph {
  fill: inherit;
  stroke-width: 0;
}

.highcharts-waterfall-series .highcharts-graph {
  stroke: #333333;
  stroke-dasharray: 1, 3;
}

.highcharts-sankey-series .highcharts-point {
  stroke-width: 0;
}

.highcharts-sankey-series .highcharts-link {
  transition: fill 250ms, fill-opacity 250ms;
  fill-opacity: 0.5;
}

.highcharts-sankey-series .highcharts-point-hover.highcharts-link {
  transition: fill 50ms, fill-opacity 50ms;
  fill-opacity: 1;
}

.highcharts-venn-series .highcharts-point {
  fill-opacity: 0.75;
  stroke: #cccccc;
  transition: stroke 250ms, fill-opacity 250ms;
}

.highcharts-venn-series .highcharts-point-hover {
  fill-opacity: 1;
  stroke: #cccccc;
}

/* Highstock */
.highcharts-navigator-mask-outside {
  fill-opacity: 0;
}

.highcharts-navigator-mask-inside {
  fill: #6685c2;
  /* navigator.maskFill option */
  fill-opacity: 0.25;
  cursor: ew-resize;
}

.highcharts-navigator-outline {
  stroke: #cccccc;
  fill: none;
}

.highcharts-navigator-handle {
  stroke: #cccccc;
  fill: #f2f2f2;
  cursor: ew-resize;
}

.highcharts-navigator-series {
  fill: #335cad;
  stroke: #335cad;
}

.highcharts-navigator-series .highcharts-graph {
  stroke-width: 1px;
}

.highcharts-navigator-series .highcharts-area {
  fill-opacity: 0.05;
}

.highcharts-navigator-xaxis .highcharts-axis-line {
  stroke-width: 0;
}

.highcharts-navigator-xaxis .highcharts-grid-line {
  stroke-width: 1px;
  stroke: #e6e6e6;
}

.highcharts-navigator-xaxis.highcharts-axis-labels {
  fill: #999999;
}

.highcharts-navigator-yaxis .highcharts-grid-line {
  stroke-width: 0;
}

.highcharts-scrollbar-thumb {
  fill: #cccccc;
  stroke: #cccccc;
  stroke-width: 1px;
}

.highcharts-scrollbar-button {
  fill: #e6e6e6;
  stroke: #cccccc;
  stroke-width: 1px;
}

.highcharts-scrollbar-arrow {
  fill: #666666;
}

.highcharts-scrollbar-rifles {
  stroke: #666666;
  stroke-width: 1px;
}

.highcharts-scrollbar-track {
  fill: #f2f2f2;
  stroke: #f2f2f2;
  stroke-width: 1px;
}

.highcharts-button {
  fill: #f7f7f7;
  stroke: #cccccc;
  cursor: default;
  stroke-width: 1px;
  transition: fill 250ms;
}

.highcharts-button text {
  fill: #333333;
}

.highcharts-button-hover {
  transition: fill 0ms;
  fill: #e6e6e6;
  stroke: #cccccc;
}

.highcharts-button-hover text {
  fill: #333333;
}

.highcharts-button-pressed {
  font-weight: bold;
  fill: #e6ebf5;
  stroke: #cccccc;
}

.highcharts-button-pressed text {
  fill: #333333;
  font-weight: bold;
}

.highcharts-button-disabled text {
  fill: #333333;
}

.highcharts-range-selector-buttons .highcharts-button {
  stroke-width: 0px;
}

.highcharts-range-label rect {
  fill: none;
}

.highcharts-range-label text {
  fill: #666666;
}

.highcharts-range-input rect {
  fill: none;
}

.highcharts-range-input text {
  fill: #333333;
}

.highcharts-range-input {
  stroke-width: 1px;
  stroke: #cccccc;
}

input.highcharts-range-selector {
  position: absolute;
  border: 0;
  width: 1px;
  /* Chrome needs a pixel to see it */
  height: 1px;
  padding: 0;
  text-align: center;
  left: -9em;
  /* #4798 */
}

.highcharts-crosshair-label text {
  fill: #ffffff;
  font-size: 1.1em;
}

.highcharts-crosshair-label .highcharts-label-box {
  fill: inherit;
}

.highcharts-candlestick-series .highcharts-point {
  stroke: #000000;
  stroke-width: 1px;
}

.highcharts-candlestick-series .highcharts-point-up {
  fill: #ffffff;
}

.highcharts-ohlc-series .highcharts-point-hover {
  stroke-width: 3px;
}

.highcharts-flags-series .highcharts-point .highcharts-label-box {
  stroke: #999999;
  fill: #ffffff;
  transition: fill 250ms;
}

.highcharts-flags-series .highcharts-point-hover .highcharts-label-box {
  stroke: #000000;
  fill: #ccd6eb;
}

.highcharts-flags-series .highcharts-point text {
  fill: #000000;
  font-size: 0.9em;
  font-weight: bold;
}

/* Highmaps */
.highcharts-map-series .highcharts-point {
  transition: fill 500ms, fill-opacity 500ms, stroke-width 250ms;
  stroke: #cccccc;
}

.highcharts-map-series .highcharts-point-hover {
  transition: fill 0ms, fill-opacity 0ms;
  fill-opacity: 0.5;
  stroke-width: 2px;
}

.highcharts-mapline-series .highcharts-point {
  fill: none;
}

.highcharts-heatmap-series .highcharts-point {
  stroke-width: 0;
}

.highcharts-map-navigation {
  font-size: 1.3em;
  font-weight: bold;
  text-align: center;
}

.highcharts-coloraxis {
  stroke-width: 0;
}

.highcharts-coloraxis-marker {
  fill: #999999;
}

.highcharts-null-point {
  fill: #f7f7f7;
}

/* 3d charts */
.highcharts-3d-frame {
  fill: transparent;
}

/* Exporting module */
.highcharts-contextbutton {
  fill: #ffffff;
  /* needed to capture hover */
  stroke: none;
  stroke-linecap: round;
}

.highcharts-contextbutton:hover {
  fill: #e6e6e6;
  stroke: #e6e6e6;
}

.highcharts-button-symbol {
  stroke: #666666;
  stroke-width: 3px;
}

.highcharts-menu {
  border: 1px solid #999999;
  background: #ffffff;
  padding: 5px 0;
  box-shadow: 3px 3px 10px #888;
}

.highcharts-menu-item {
  padding: 0.5em 1em;
  background: none;
  color: #333333;
  cursor: pointer;
  transition: background 250ms, color 250ms;
}

.highcharts-menu-item:hover {
  background: #335cad;
  color: #ffffff;
}

/* Drilldown module */
.highcharts-drilldown-point {
  cursor: pointer;
}

.highcharts-drilldown-data-label text,
text.highcharts-drilldown-data-label,
.highcharts-drilldown-axis-label {
  cursor: pointer;
  fill: #003399;
  font-weight: bold;
  text-decoration: underline;
}

/* No-data module */
.highcharts-no-data text {
  font-weight: bold;
  font-size: 12px;
  fill: #666666;
}

/* Drag-panes module */
.highcharts-axis-resizer {
  cursor: ns-resize;
  stroke: black;
  stroke-width: 2px;
}

/* Bullet type series */
.highcharts-bullet-target {
  stroke-width: 0;
}

/* Lineargauge type series */
.highcharts-lineargauge-target {
  stroke-width: 1px;
  stroke: #333333;
}

.highcharts-lineargauge-target-line {
  stroke-width: 1px;
  stroke: #333333;
}

/* Annotations module */
.highcharts-annotation-label-box {
  stroke-width: 1px;
  stroke: #000000;
  fill: #000000;
  fill-opacity: 0.75;
}

.highcharts-annotation-label text {
  fill: #e6e6e6;
}

/* Gantt */
.highcharts-treegrid-node-collapsed, .highcharts-treegrid-node-expanded {
  cursor: pointer;
}

.highcharts-point-connecting-path {
  fill: none;
}

.highcharts-grid-axis .highcharts-tick {
  stroke-width: 1px;
}

.highcharts-grid-axis .highcharts-axis-line {
  stroke-width: 1px;
}
/* General data label style */
.highcharts-data-label text {
    fill: black;
    text-shadow: 1px 1px white, 1px -1px white, -1px -1px white, -1px 1px white;
}

/* For the darkest columns, invert the data label text color and shadow */
.highcharts-data-label-color-0 text,
.highcharts-data-label-color-1 text,
.highcharts-data-label-color-4 text,
.highcharts-data-label-color-5 text,
.highcharts-data-label-color-7 text {
    fill: white;
    text-shadow: 1px 1px black, 1px -1px black, -1px -1px black, -1px 1px black;
}
</style>
